import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@material-ui/core";
import AdminFilter from "~/Admin/AdminFilter";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import axios from "axios";

const LatestMatchTicketRequests = ({classes}) => {
    const [assetRequests, setAssetRequests] = useState([]);

    const [filters, setFilters] = useState({
        search: '',
        sort: 'created_at',
        perPage: 5,
    });

    const getAssetRequests = () => {
        axios.get('/api/match-ticket-requests?pending').then(res => {
            setAssetRequests(res.data.data);
        });
    }

    useEffect(getAssetRequests, []);

    return (
        <Grid container direction="column" className={classes.assetWrapper} spacing={4}>
            <Grid item>
                <Grid container direction="row" justifyContent="flex-start" spacing={4}>
                    <Grid item md={4}>
                        <Grid container direction="column">
                            <Grid item>
                                <Typography variant="subtitle2">
                                    Matches
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        <Typography color="primary">LATEST REQUESTS</Typography>
                    </Grid>
                    <Grid item>
                        <AdminFilter values={filters} onChange={setFilters} sortFields={{
                            'created_at': 'Date created',
                        }}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={{borderBottom: '2px solid #01B7AB'}}>
                                <TableCell><Typography color="secondary">PARTNER</Typography></TableCell>
                                <TableCell><Typography color="secondary">FIXTURE</Typography></TableCell>
                                <TableCell><Typography color="secondary">MATCH DATE</Typography></TableCell>
                                <TableCell><Typography color="secondary">TOURNAMENT</Typography></TableCell>
                                <TableCell><Typography color="secondary">VENUE</Typography></TableCell>
                                <TableCell><Typography color="secondary">TIER</Typography></TableCell>
                                <TableCell><Typography color="secondary">TICKET TYPE</Typography></TableCell>
                                <TableCell><Typography color="secondary">CAPACITY</Typography></TableCell>
                                <TableCell><Typography color="secondary">IN USE</Typography></TableCell>
                                <TableCell><Typography color="secondary">REMAINING</Typography></TableCell>
                                <TableCell><Typography color="secondary">STATUS</Typography></TableCell>
                                <TableCell><Typography color="secondary">REQUESTS</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetRequests.map((assetRequest, key) => {
                                return (
                                    <TableRow key={key}>
                                        <TableCell><Typography>{assetRequest.requestable?.partner?.name}</Typography></TableCell>
                                        <TableCell><Typography>{assetRequest.requestable?.away_team}</Typography></TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Moment format="DD/MM/YYYY"
                                                        className="moment-date">{assetRequest.requestable?.match_date}</Moment>
                                            </Typography>
                                        </TableCell>
                                        <TableCell><Typography>{assetRequest.requestable?.tournament?.name}</Typography></TableCell>
                                        <TableCell><Typography>{assetRequest.requestable?.venue}</Typography></TableCell>
                                        <TableCell><Typography>{assetRequest.requestable?.tier}</Typography></TableCell>
                                        <TableCell><Typography>Ticket type</Typography></TableCell>
                                        <TableCell><Typography>{assetRequest.requestable?.available}</Typography></TableCell>
                                        <TableCell><Typography>33</Typography></TableCell>
                                        <TableCell><Typography>33</Typography></TableCell>
                                        <TableCell><Typography>{assetRequest.status}</Typography></TableCell>
                                        <TableCell>
                                            <Grid container direction="column">
                                                <Typography style={{cursor: 'pointer'}} color="secondary">
                                                    <Link className={classes.link} to={`/admin/assets/approvals/match-tickets/${assetRequest.id}`}>View</Link>
                                                </Typography>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default LatestMatchTicketRequests;
