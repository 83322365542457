import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import moment from 'moment';

export default ({request}) => {
  return (
    <Grid container className="events-block-new cards">
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <h4>Submission details</h4>
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography style={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.57,
                  color: '#999bb5',
                }}>SUBMISSION DATE:</Typography>
              </Grid>
              <Grid item>
                <Typography>{moment(request.created_at).format('DD/MM/YYYY')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography style={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.57,
                  color: '#999bb5',
                }}>UPDATED ON:</Typography>
              </Grid>
              <Grid item>
                <Typography>{moment(request.updated_at).format('DD/MM/YYYY')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography style={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.57,
                  color: '#999bb5',
                }}>STATUS:</Typography>
              </Grid>
              <Grid item>
                <Typography className="request-status">{request.status === 'change' ? 'Awaiting Changes' : request.status}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
