import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { getTicketsHistoryRequests } from './MyRequestsActions';
import TicketRequestLayout from './components/TicketRequestsLayout';
import TableHeader from '../Components/TableMui/TableHeader';
import TableRequestsFilter from '../Components/TableMui/TableRequestsFilter';
import TableHead from '../Components/TableMui/TableHeadComponent';
import TicketRequestFilterRow from './components/TicketRequestsFilterRow';
import TicketRequestsListDisplay from './components/TicketRequestsListDisplay';
import { Pagination } from '@material-ui/lab';

const tableHead = [
  { title: 'order date' },
  { title: 'partner' },
  { title: 'fixture' },
  { title: 'match date', align: 'center' },
  { title: 'tournament', align: 'center' },
  { title: 'ticket type', align: 'center' },
  { title: 'requested qty', align: 'center' },
  { title: 'approved qty', align: 'center' },
  { title: 'status', align: 'center' },
  { title: 'action', align: 'right' },
];

const TicketRequestHistory = ({ ticketsHistoryRequests, dispatch }) => {
  const [searchRequests, setSearchRequests] = useState('');
  const [sortRequest, setSortRequest] = useState('Date');
  const [perPage, setPerPage] = useState(10);
  const [toggleList, setToggleList] = useState(false);
  const [toggleFilterSettings, setToggleFilterSettings] = useState(false);
  const [orderDateSearch, setOrderDateSearch] = useState('');
  const [partnerSelect, setPartnerSelect] = useState('All');
  const [fixtureSearch, setfixtureSearch] = useState('');
  const [matchDateSearch, setMatchDateSearch] = useState('');
  const [tournamentSelect, setTournamentSelect] = useState('All');
  const [ticketTypeSelect, setTicketTypeSelect] = useState('All');
  const [statusSelect, setStatusSelect] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getTicketsHistoryRequests(currentPage, perPage));
  }, [currentPage, perPage]);

  const searchRequestsHandler = (e) => {
    setSearchRequests(e);
  };
  const sortRequestsHandler = (e) => {
    setSortRequest(e);
  };
  const showRequestHandler = (e) => {
    setPerPage(e);
  };
  const toggleListHandler = () => {
    setToggleList(!toggleList);
  };
  const toggleFilterSettingsHandler = () => {
    setToggleFilterSettings(!toggleFilterSettings);
  };
  const orderDateSearchHandler = (e) => {
    setOrderDateSearch(e);
  };
  const partnerSelectHandler = (e) => {
    setPartnerSelect(e);
  };
  const fixtureSearchHandler = (e) => {
    setfixtureSearch(e);
  };
  const matchDateSearchHandler = (e) => {
    setMatchDateSearch(e);
  };
  const tournamentSelectHandler = (e) => {
    setTournamentSelect(e);
  };
  const ticketTypeSelectHandler = (e) => {
    setTicketTypeSelect(e);
  };
  const statusSelctHandler = (e) => {
    setStatusSelect(e);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  }

  return (
    <>
      <TicketRequestLayout>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TableHeader
              title="Ticket Requests History"
              style={{ marginBottom: '9px' }}
            />
            <TableRequestsFilter
              filterTitle="submitted requests"
              searchHandler={searchRequestsHandler}
              sortState={sortRequest}
              sortHandler={sortRequestsHandler}
              showState={perPage}
              showHandler={showRequestHandler}
              listState={toggleList}
              listHandler={toggleListHandler}
              filterSettingsState={toggleFilterSettings}
              filtetSettingsHandler={toggleFilterSettingsHandler}
            />
            <TableContainer>
              <Table>
                <TableHead tableHead={tableHead} />
                <TableBody>
                  {toggleFilterSettings && (
                    <TicketRequestFilterRow
                      orderDateSearchHandler={orderDateSearchHandler}
                      partnerSelectState={partnerSelect}
                      partnerSelectHandler={partnerSelectHandler}
                      fixtureSearchHandler={fixtureSearchHandler}
                      matchDateSearchHandler={matchDateSearchHandler}
                      tournamentSelectState={tournamentSelect}
                      tournamentSelectHandler={tournamentSelectHandler}
                      ticketTypeSelectState={ticketTypeSelect}
                      ticketTypeSelectHandler={ticketTypeSelectHandler}
                      statusSelectState={statusSelect}
                      statusSelctHandler={statusSelctHandler}
                      statusOptions={['approved', 'cancelled', 'rejected']}
                    />
                  )}
                  <TicketRequestsListDisplay data={ticketsHistoryRequests} />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {
            ticketsHistoryRequests?.per_page < ticketsHistoryRequests?.total && (
              <Grid item>
                <Grid container direction="column" alignContent="flex-end">
                  <Pagination
                    shape="rounded"
                    count={ticketsHistoryRequests.last_page}
                    page={ticketsHistoryRequests.current_page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </TicketRequestLayout>

    </>
  );
};

const mapsStateToProps = ({ myRequests }) => ({
  ticketsHistoryRequests: myRequests.ticketsHistoryRequests
})

export default connect(mapsStateToProps)(TicketRequestHistory);
