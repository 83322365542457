import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik } from "formik";
import { Button, Divider, Grid, makeStyles, TextField, Typography, Drawer, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Upload from "~/Components/Upload";
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from "yup";
import { debounce } from "lodash";
import { fetchUsersList } from "~/actions/partnerActions";
import { connect } from "react-redux";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({

    closeButton: {
        cursor: 'pointer',
        float: 'right',
    },
    createPartnerButton: {
        backgroundColor: theme.primaryColor,
        color: 'white',
        fontWeight: 600,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#af001eA5',
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: '#af001e80'
        }
    },
    cancelPartnerButton: {
        backgroundColor: '#d8dae6',
        fontWeight: 600,
        color: '#3e3f60',
        borderRadius: 0
    },
    dividerLink: {
        backgroundColor: theme.primaryColor
    },
    notice: {
        maxWidth: 366,
        fontSize: 10,
        color: theme.lightGrey,
    },
    dropDownLabel: {
        width: 137,
        height: 22,
        fontSize: 14,
        fontWeight: 600,
        color: theme.dark,
    },
    createPartnerPadding: {
        padding: 20,
        minWidth: 400
    },
}));

const CreatePartner = (props) => {

    const classes = useStyles();
    const {
        createPartnerDrawer,
        handleDrawerClose,
        handleAddPartner,
        setShowDrawer,
        loading,
        users,
        users_dropdowns,
        active_partner_data
    } = props;

    const [openUpload, setOpenUpload] = useState(!active_partner_data)
    const [userSearch, setUserSearch] = useState('');

    const RequestSchema = Yup.object().shape({
        partner_name: Yup.string()
            .required('Required'),
        logo: Yup.mixed()
            .required('A file is required'),
        user_managers: Yup.array()
            .min(1, 'Required')
            .max(3, 'Maximum 3 managers'),
        partnership_managers: Yup.array()
            .min(1, 'Required')
            .max(3, 'Maximum 3 managers'),
    });

    const initialValues = {
        user_managers: [],
        partnership_managers: [],
        partner_name: '',
        logo: null,
    };

    const searchUsersHandler = (search) => {
        props.dispatch(fetchUsersList(search));
    }

    const debouncedOnInput = debounce(searchUsersHandler, 300);
    const inputHandler = useCallback((search) => {
        debouncedOnInput(search);
    }, []);

    useEffect(() => {
        inputHandler(userSearch);
    }, [userSearch]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Drawer
            BackdropProps={{ invisible: true }}
            anchor="right"
            open={createPartnerDrawer && users_dropdowns && Object.keys(users_dropdowns).length > 0}
            onClose={() => {
                handleDrawerClose();
                alert('test');
            }}
            classes={{ paper: classes.createPartnerPadding }}>
            <Formik
                validationSchema={RequestSchema}
                onSubmit={handleAddPartner}
                enableReinitialize
                initialValues={initialValues}>
                {({ values, touched, errors, setFieldValue }) => (
                    <Form>
                        <Grid container direction="column" spacing={3} >
                            <Grid item>
                                <Close onClick={handleDrawerClose} className={classes.closeButton} />
                            </Grid>
                            <Grid item>
                                <Field name="partner_name">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...field}
                                                    fullWidth
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: 0,
                                                            borderColor: '#c6c9db'
                                                        }
                                                    }}
                                                    value={values.partner_name || ''}
                                                    placeholder="New Partner"
                                                    error={touched.partner_name && !!errors.partner_name ? true : false}
                                                    helperText={touched.partner_name && !!errors.partner_name ? errors.partner_name : ''}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Typography>Partner Logo</Typography>
                            </Grid>
                            <Grid item>
                                {openUpload &&
                                    <>
                                        <Upload sendFiles={(file) => setFieldValue('logo', file[0])} accept=".jpg,.jpeg,.png" />
                                        <p className="input-error">
                                            {touched.logo && !values.logo ? errors.logo : null}
                                        </p>
                                        <p>
                                            {values.logo ? values.logo.path : ''}
                                        </p>
                                    </>
                                }
                                {active_partner_data && !openUpload &&
                                    <>
                                        <div>
                                            <img style={{ maxWidth: 367 }} src={active_partner_data.logo} alt={active_partner_data.name} />
                                        </div>
                                        <div>
                                            <Button onClick={() => setOpenUpload(true)}>Update Logo</Button>
                                        </div>
                                    </>
                                }
                            </Grid>
                            <Divider className={classes.dividerLink} />
                            <Grid item>
                                <Typography>Permissions</Typography>
                            </Grid>
                            <Grid item>

                            </Grid>
                            <Grid item>
                                <Field name="partnership_managers">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography className={classes.dropDownLabel}>Partnership Manager</Typography>
                                                <Typography className={classes.notice}>Choose up to 3 partnership manager. You will only be able to choose admin users with a XXX role</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    debug={true}
                                                    options={users}
                                                    onChange={(event, value) => {
                                                        setFieldValue('partnership_managers', []);
                                                        value.forEach((item, index) => {
                                                            setFieldValue(`partnership_managers.${index}`, item.id);
                                                        })
                                                    }}
                                                    onInputChange={(event) => setUserSearch(event.target ? event.target.value : '')}
                                                    fullWidth
                                                    disableCloseOnSelect
                                                    filterOptions={(options, state) => options}
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                                disabled={true}
                                                                value={option.id}
                                                            />
                                                            {option.name}
                                                        </React.Fragment>
                                                    )}
                                                    getOptionSelected={((option, value) => option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: 0,
                                                                    borderColor: '#c6c9db'
                                                                }
                                                            }}
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error" style={{ maxWidth: 400 }}>
                                                    {touched.partnership_managers ? errors.partnership_managers : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Field name="user_managers">
                                    {({ field }) => (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography className={classes.dropDownLabel}>User Manager</Typography>
                                                <Typography className={classes.notice}>Choose up to 3 user manager. You will only be able to choose users with a XXX role</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    debug={true}
                                                    options={users}
                                                    onChange={(event, value) => {
                                                        setFieldValue('user_managers', []);
                                                        value.forEach((item, index) => {
                                                            setFieldValue(`user_managers.${index}`, item.id);
                                                        })

                                                    }}

                                                    onInputChange={(event) => setUserSearch(event.target ? event.target.value : '')}
                                                    fullWidth
                                                    disableCloseOnSelect
                                                    filterOptions={(options, state) => options}
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                                value={option.id}
                                                                disabled={true}
                                                            />
                                                            {option.name}
                                                        </React.Fragment>
                                                    )}
                                                    getOptionSelected={((option, value) => option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: 0,
                                                                    borderColor: '#c6c9db'
                                                                }
                                                            }}
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="input-error" style={{ maxWidth: 400 }}>
                                                    {touched.user_managers ? errors.user_managers : null}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        {loading ?
                                            <CircularProgress color="secondary" />
                                            :
                                            <Button
                                                size="large"
                                                fullWidth
                                                className={classes.createPartnerButton}
                                                // disabled={!isValid}
                                                type="submit">Create Partner</Button>}
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="large"
                                            fullWidth
                                            onClick={handleDrawerClose}
                                            className={classes.cancelPartnerButton}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default connect(null, null)(CreatePartner);
