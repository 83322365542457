import React, { useState } from 'react';
import { Divider, Grid, Tabs, Tab, makeStyles, Card, Switch, Typography, FormControlLabel, Button } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { Field } from 'formik';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { resetOverridesData } from '../cmsInitialValues';
import { EditorState } from 'draft-js';
import Dropzone from "react-dropzone";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}>
            {value === index && (
                children
            )}
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: theme.primaryColor,
    },
    tab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
    },
}));

const Requests = (props) => {

    const {
        setFieldValue,
        talentAccessFiles,
        setTalentAccessFiles,
        approvalsFiles,
        setApprovalsFiles,
        approvalsSwitchState,
        setApprovalsSwitchState,
        talentAccessSwitchState,
        setTalentAccessSwitchState,
        talentAccessEditorState,
        setTalentAccessEditorState,
        approvalsEditorState,
        setApprovalsEditorState,
        previewUpload,
        setRenameImage,
        handleOpenImageDialog,
        setRenameSection,
        setRenameRenameFileIndex,
        user
    } = props;

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const checker = new PermissionChecker(user);

    const handleSwitchChange = (type, event) => {
        switch (type) {
            case 'talent_access':
                setTalentAccessSwitchState(event.target.checked);
                setFieldValue('requests.override_talent_access', event.target.checked);
                break;
            case 'approvals':
                setApprovalsSwitchState(event.target.checked);
                setFieldValue('requests.override_approvals', event.target.checked);
                break;
        }
    };

    // RESET SINGLE FIELD STATE TO INITIAL
    const resetField = (field) => {
        switch (field) {
            case "talent_access":
                setFieldValue('requests.talent_access', resetOverridesData.requests.talent_access);
                setTalentAccessEditorState(EditorState.createWithContent(convertFromHTML(resetOverridesData.requests.talent_access)));
                break;
            case "approvals":
                setFieldValue('requests.approvals', resetOverridesData.requests.approvals);
                setApprovalsEditorState(EditorState.createWithContent(convertFromHTML(resetOverridesData.requests.approvals)));
                break;
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const menuItems = [
        checker.some([
            "admin_view_talent_access",
            'edit_talent_access',
            'upload_delete_talent_access_files'
        ]) && {
            name: 'Talent Access',
            component: <Grid container direction="column" style={{ padding: 30 }}>
                <Grid item>
                    <Permitted one="admin_view_talent_access">
                        <Grid container direction="row">
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography
                                            style={{
                                                color: '#3e3f60',
                                                fontSize: 14,
                                                fontWeight: 'bold'
                                            }}>
                                            Partner Tier Access
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{
                                                fontSize: 10,
                                                color: '#999bb5'
                                            }}>
                                            Edit to the below text will only be applied for the selected partner tier.
                                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={talentAccessSwitchState}
                                                    onChange={(e) => {
                                                        handleSwitchChange('talent_access', e);
                                                    }}
                                                />
                                            }
                                            label={talentAccessSwitchState ? 'Activated' : 'Deactivated'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                talentAccessSwitchState &&
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Grid container direction="row" spacing={6}>
                                                <Permitted one="edit_talent_access">
                                                    <Grid item xs sm md xl lg>
                                                        <Grid container direction="column" spacing={2}>
                                                            <Grid item>
                                                                <Typography style={{ color: '#3e3f60', fontSize: 14, fontWeight: 'bold' }}>Talent Access</Typography>
                                                                <Grid container direction="row" alignItems="baseline" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography style={{ fontSize: 10, color: '#999bb5' }}>Edit to the below text will only be applied for the selected partner tier.</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Field name="talent_access">
                                                                    {({ field }) => (
                                                                        <Editor
                                                                            {...field}
                                                                            name="talent_access"
                                                                            editorState={talentAccessEditorState}
                                                                            editorStyle={{
                                                                                border: '1px solid #c6c9db',
                                                                                padding: 10,
                                                                                minHeight: 300,
                                                                                maxHeight: 300,
                                                                                overflowY: 'auto'
                                                                            }}
                                                                            placeholder="Please add content here"
                                                                            onEditorStateChange={(e) => {
                                                                                setFieldValue('requests.talent_access', convertToHTML(e.getCurrentContent()));
                                                                                setTalentAccessEditorState(e);
                                                                            }}
                                                                            toolbar={{
                                                                                options: ['inline', 'list'],
                                                                                inline: {
                                                                                    options: ['bold', 'italic', 'underline'],
                                                                                },
                                                                                list: {
                                                                                    options: ['unordered'],
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Permitted>
                                                <Permitted one="upload_delete_talent_access_files">
                                                    <Grid item xs={5} sm={5} md={5} xl={5} lg={5}>
                                                        <Grid container direction="column" justifyContent="space-between" style={{ minHeight: '100%' }}>
                                                            <Grid item>
                                                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography style={{ color: '#3e3f60', fontSize: 14, fontWeight: 'bold' }}>Documents</Typography>
                                                                        { talentAccessFiles.length === 0 && <Typography style={{ color: '#999bb5', fontSize: 10 }}>No documents yet.</Typography> }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="column" spacing={4}>
                                                                    {
                                                                        talentAccessFiles && talentAccessFiles.map((file, key) => (
                                                                            <Grid item key={key}>
                                                                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                                                                    <Grid item>
                                                                                        <Grid container direction="row" alignItems="baseline" spacing={1}>
                                                                                            <Grid item>
                                                                                                {
                                                                                                    (file.type === 'image/jpeg' || file.type === 'image/png' || file.ext === 'jpg' || file.ext === 'png') && <img src="/images/icons/SVG/File Type/Image.svg" style={{ width: 21, height: 21 }} alt="image" />
                                                                                                }
                                                                                                {
                                                                                                    (file.type === 'application/pdf' || file.ext === 'pdf') && <img src="/images/icons/SVG/File Type/PDF.svg" style={{ width: 21, height: 21 }} alt="pdf" />
                                                                                                }
                                                                                                {
                                                                                                    (file.type === 'application/msword' || file.type === 'application/vnd.ms-excel' || file.type === 'application/xslt+xml' || file.ext === 'xsl' || file.ext === 'doc') && <img src="/images/icons/SVG/File Type/File.svg" style={{ width: 21, height: 21 }} alt="file" />
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <Typography style={{ color: '#3e3f60', fontSize: 16 }}>{file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name}</Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <Typography style={{ color: '#d8dae6', fontSize: 10, fontWeight: 'bold' }}>
                                                                                                    {
                                                                                                        typeof file.size === 'number' ? (file.size > 0 && (file.size * 0.000001).toFixed(2)) : (Number(file.size) > 0 && (Number(file.size) / 1000).toFixed(2))
                                                                                                    }
                                                                                                    MB
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Grid container direction="row" spacing={2}>
                                                                                            <Grid item>
                                                                                                <img
                                                                                                    src="/images/icons/SVG/View.svg"
                                                                                                    style={{
                                                                                                        width: 21,
                                                                                                        height: 21,
                                                                                                        cursor: 'pointer'
                                                                                                    }}
                                                                                                    alt="view"
                                                                                                    onClick={() => previewUpload(file)} />
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <img
                                                                                                    src="/images/icons/SVG/Edit.svg"
                                                                                                    style={{
                                                                                                        width: 21,
                                                                                                        height: 21
                                                                                                    }}
                                                                                                    alt="edit"
                                                                                                    onClick={() => {
                                                                                                        setRenameImage(file.name);
                                                                                                        setRenameSection('talen_access_files');
                                                                                                        setRenameRenameFileIndex(key);
                                                                                                        handleOpenImageDialog();
                                                                                                    }} />
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <img
                                                                                                    src="/images/icons/SVG/Close.svg"
                                                                                                    style={{
                                                                                                        width: 21,
                                                                                                        height: 21,
                                                                                                        cursor: 'pointer'
                                                                                                    }}
                                                                                                    alt="image"
                                                                                                    onClick={() => {
                                                                                                        let newFiles = talentAccessFiles.filter((item, iKey) => iKey !== key);
                                                                                                        setTalentAccessFiles(newFiles);
                                                                                                        setFieldValue('requests.talent_access_files', newFiles)
                                                                                                    }} />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="column" spacing={2}>
                                                                    <Grid item>
                                                                        <Typography style={{ fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase', color: '#af001e' }}>Upload files</Typography>
                                                                        <Typography style={{ fontSize: 10, color: '#999bb5' }}>You can upload new files to be uploaded below by either clicking on the button below, or dropping the file on the button</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Dropzone
                                                                            accept=".pdf,.doc,.jpg,.png,.xsl"
                                                                            minSize={0}
                                                                            onDrop={x => {
                                                                                let newFiles = [];
                                                                                if (talentAccessFiles && talentAccessFiles.length > 0) {
                                                                                    newFiles = [...talentAccessFiles, ...x];
                                                                                } else {
                                                                                    newFiles = [...x];
                                                                                }
                                                                                setTalentAccessFiles(newFiles);
                                                                                setFieldValue('requests.talent_access_files', newFiles)
                                                                            }}>
                                                                            {({
                                                                                getRootProps,
                                                                                getInputProps,
                                                                                isDragActive,
                                                                                isDragReject
                                                                            }) => {
                                                                                return (
                                                                                    <div style={{
                                                                                        width: '100%',
                                                                                        background: '#f3f4f8',
                                                                                        border: '3px dashed #d8dae6',
                                                                                        color: '#3e3f60',
                                                                                        padding: 30,
                                                                                        textAlign: 'center',
                                                                                        position: 'relative'
                                                                                    }}
                                                                                        {...getRootProps()}>
                                                                                        <div className="upload-icon">
                                                                                            <img src="/images/icons/SVG/Upload-red.svg" alt="upload" />
                                                                                        </div>
                                                                                        <h4 className="upload-title">
                                                                                            Drag & Drop
                                                                                    </h4>
                                                                                        <div className="form-group">
                                                                                            <div className="dropZone">
                                                                                                <input {...getInputProps()} />
                                                                                                {!isDragActive && <span>Or you can <u>browse</u> your files</span>}
                                                                                                {isDragActive && isDragReject && "Drop it like it's hot!"}
                                                                                                {isDragActive && !isDragReject && "File type not accepted, sorry!"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Dropzone>
                                                                        <Typography style={{ fontSize: 10, color: '#999bb5' }}>Accepted Formats: pdf, doc, jpg, png, xsl</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Permitted>
                                            </Grid>
                                        </Grid>
                                        <Permitted
                                            one='edit_talent_access'>
                                            <Grid item>
                                                <Button
                                                    style={{
                                                        color: '#af001e',
                                                        fontSize: 16,
                                                        fontWeight: 'bold',
                                                        textTransform: 'uppercase'
                                                    }}
                                                    onClick={() => resetField('talent_access')}>
                                                    Reset Overrides
                                        </Button>
                                            </Grid>
                                        </Permitted>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Permitted>
                </Grid>
            </Grid>
        },
        checker.some([
            'edit_approvals',
            'upload_delete_approvals_files'
        ]) && {
            name: 'Approvals',
            component: <Grid container direction="column" style={{ padding: 30 }}>
                <Grid item>
                    <Grid container direction="row">
                        <Grid container direction="column">
                            <Grid item>
                                <Typography
                                    style={{
                                        color: '#3e3f60',
                                        fontSize: 14,
                                        fontWeight: 'bold'
                                    }}>
                                    Partner Tier Access
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        fontSize: 10,
                                        color: '#999bb5'
                                    }}>
                                    Edit to the below text will only be applied for the selected partner tier.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={approvalsSwitchState}
                                            onChange={(e) => {
                                                handleSwitchChange('approvals', e);
                                            }}
                                        />
                                    }
                                    label={approvalsSwitchState ? 'Activated' : 'Deactivated'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {
                        approvalsSwitchState &&
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container direction="row" spacing={6}>
                                    <Permitted one="edit_approvals">
                                        <Grid item xs sm md xl lg>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Typography style={{ color: '#3e3f60', fontSize: 14, fontWeight: 'bold' }}>Approvals</Typography>
                                                    <Grid container direction="row" alignItems="baseline" justifyContent="space-between">
                                                        <Grid item>
                                                            <Typography style={{ fontSize: 10, color: '#999bb5' }}>Edit to the below text will only be applied for the selected partner tier.</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Field name="approvals">
                                                        {({ field }) => (
                                                            <Editor
                                                                {...field}
                                                                name="approvals"
                                                                editorState={approvalsEditorState}
                                                                editorStyle={{
                                                                    border: '1px solid #c6c9db',
                                                                    padding: 10,
                                                                    minHeight: 300,
                                                                    maxHeight: 300,
                                                                    overflowY: 'auto'
                                                                }}
                                                                placeholder="Please add content here"
                                                                onEditorStateChange={(e) => {
                                                                    setFieldValue('requests.approvals', convertToHTML(e.getCurrentContent()));
                                                                    setApprovalsEditorState(e);
                                                                }}
                                                                toolbar={{
                                                                    options: ['inline', 'list'],
                                                                    inline: {
                                                                        options: ['bold', 'italic', 'underline'],
                                                                    },
                                                                    list: {
                                                                        options: ['unordered'],
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Permitted>
                                    <Permitted one="upload_delete_approvals_files">
                                        <Grid item xs={5} sm={5} md={5} xl={5} lg={5}>
                                            <Grid container direction="column" justifyContent="space-between" style={{ minHeight: '100%' }}>
                                                <Grid item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                                        <Grid item>
                                                            <Typography style={{ color: '#3e3f60', fontSize: 14, fontWeight: 'bold' }}>Documents</Typography>
                                                            { approvalsFiles.length === 0 && <Typography style={{ color: '#999bb5', fontSize: 10 }}>No documents yet.</Typography> }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="column" spacing={4}>
                                                        {approvalsFiles && approvalsFiles.map((file, key) => (
                                                            <Grid item key={key}>
                                                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Grid container direction="row" alignItems="baseline" spacing={1}>
                                                                            <Grid item>
                                                                                {
                                                                                    (file.type === 'image/jpeg' || file.type === 'image/png' || file.ext === 'jpg' || file.ext === 'png') && <img src="/images/icons/SVG/File Type/Image.svg" style={{ width: 21, height: 21 }} alt="image" />
                                                                                }
                                                                                {
                                                                                    (file.type === 'application/pdf' || file.ext === 'pdf') && <img src="/images/icons/SVG/File Type/PDF.svg" style={{ width: 21, height: 21 }} alt="pdf" />
                                                                                }
                                                                                {
                                                                                    (file.type === 'application/msword' || file.type === 'application/vnd.ms-excel' || file.type === 'application/xslt+xml' || file.ext === 'xsl' || file.ext === 'doc') && <img src="/images/icons/SVG/File Type/File.svg" style={{ width: 21, height: 21 }} alt="file" />
                                                                                }
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography style={{ color: '#3e3f60', fontSize: 16 }}>{file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name}</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography style={{ color: '#d8dae6', fontSize: 10, fontWeight: 'bold' }}>
                                                                                    {
                                                                                        typeof file.size === 'number' ? (file.size > 0 && (file.size * 0.000001).toFixed(2)) : (Number(file.size) > 0 && (Number(file.size) / 1000).toFixed(2))
                                                                                    }
                                                                             MB</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="row" spacing={2}>
                                                                            <Grid item>
                                                                                <img
                                                                                    src="/images/icons/SVG/View.svg"
                                                                                    style={{
                                                                                        width: 21,
                                                                                        height: 21,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    alt="view"
                                                                                    onClick={() => previewUpload(file)} />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <img
                                                                                    src="/images/icons/SVG/Edit.svg"
                                                                                    style={{
                                                                                        width: 21,
                                                                                        height: 21,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    alt="edit"
                                                                                    onClick={() => {
                                                                                        setRenameImage(file.name);
                                                                                        setRenameSection('approval_files');
                                                                                        setRenameRenameFileIndex(key);
                                                                                        handleOpenImageDialog();
                                                                                    }} />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <img
                                                                                    src="/images/icons/SVG/Close.svg"
                                                                                    style={{
                                                                                        width: 21,
                                                                                        height: 21,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    alt="image"
                                                                                    onClick={() => {
                                                                                        let newFiles = approvalsFiles.filter((item, iKey) => iKey !== key);
                                                                                        setApprovalsFiles(newFiles);
                                                                                        setFieldValue('requests.approvals_files', newFiles);
                                                                                    }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item>
                                                            <Typography style={{ fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase', color: '#af001e' }}>Upload files</Typography>
                                                            <Typography style={{ fontSize: 10, color: '#999bb5' }}>You can upload new files to be uploaded below by either clicking on the button below, or dropping the file on the button</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Dropzone
                                                                accept=".pdf,.doc,.jpg,.png,.xsl"
                                                                minSize={0}
                                                                onDrop={x => {
                                                                    let newFiles = [];
                                                                    if (approvalsFiles && approvalsFiles.length > 0) {
                                                                        newFiles = [...approvalsFiles, ...x];
                                                                    } else {
                                                                        newFiles = [...x];
                                                                    }
                                                                    setApprovalsFiles(newFiles);
                                                                    setFieldValue('requests.approvals_files', newFiles)
                                                                }}>
                                                                {({
                                                                    getRootProps,
                                                                    getInputProps,
                                                                    isDragActive,
                                                                    isDragReject
                                                                }) => {
                                                                    return (
                                                                        <div style={{
                                                                            width: '100%',
                                                                            background: '#f3f4f8',
                                                                            border: '3px dashed #d8dae6',
                                                                            color: '#3e3f60',
                                                                            padding: 30,
                                                                            textAlign: 'center',
                                                                            position: 'relative'
                                                                        }}
                                                                            {...getRootProps()}>
                                                                            <div className="upload-icon">
                                                                                <img src="/images/icons/SVG/Upload-red.svg" alt="upload" />
                                                                            </div>
                                                                            <h4 className="upload-title">
                                                                                Drag & Drop
                                                                    </h4>
                                                                            <div className="form-group">
                                                                                <div className="dropZone">
                                                                                    <input {...getInputProps()} />
                                                                                    {!isDragActive && <span>Or you can <u>browse</u> your files</span>}
                                                                                    {isDragActive && isDragReject && "Drop it like it's hot!"}
                                                                                    {isDragActive && !isDragReject && "File type not accepted, sorry!"}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Dropzone>
                                                            <Typography style={{ fontSize: 10, color: '#999bb5' }}>Accepted Formats: pdf, doc, jpg, png, xsl</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Permitted>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    style={{
                                        color: '#af001e',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }}
                                    onClick={() => resetField('approvals')}>
                                    Reset Overrides
                            </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        }
    ];

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Card style={{ borderRadius: 0, minHeight: 500 }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                classes={{
                                    indicator: classes.tabs,
                                }}>
                                {
                                    menuItems
                                        .filter(x => x != false)
                                        .map((item, index) => (
                                            <Tab key={index} label={item.name} />
                                        ))
                                }
                            </Tabs>
                        </Grid>
                        <Divider />
                        {
                            menuItems
                                .filter(x => x != false)
                                .map((item, index) => (
                                    <TabPanel
                                        key={index}
                                        value={value}
                                        index={index}>
                                        {item.component}
                                    </TabPanel>
                                ))
                        }
                    </Grid>
                </Card>
            </Grid>
        </Grid >
    );
};

export default Requests;
