import React from 'react';
import { Grid, TextField, Typography, Button, Radio } from '@material-ui/core';
import CalendarIcon from '~/Components/Icons/CalendarIcon';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles, withStyles } from '@material-ui/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from "moment";
import { getKeyDeadlines } from '../dashboardActions';
import { connect } from 'react-redux';

const StyledRadioButton = withStyles({
    root: {
        marginLeft: '12px',
        padding: '0',
        width: '18px',
        height: '18px',
        border: '1px solid #01B7AB',
        disableUnderline: true,
        outline: '0',
        marginRight: '12px'
    },
})(Radio);

const useStyles = makeStyles((theme) => ({
    overTitle: {
        fontFamily: theme.boldFont,
        marginBottom: 12,
        fontSize: 16
    },
    title: {
        fontFamily: theme.ultraBoldFont,
        fontSize: 30,
        marginBottom: 8
    },
    sectionTitle: {
        fontSize: 24,
        fontFamily: theme.boldFont,
        marginTop: 10,
        marginBottom: 14
    },
    mainGridContainer: {
        width: '100%',
        borderRadius: '20px',
        backgroundColor: 'white',
        padding: '45px 83px 51px 60px',
        margin: '0 auto',
        boxShadow: '0px 3px 6px #00000029'
    },
    textInputFieldDeadlineDate: {
        height: '24px',
        width: '200px',
        border: '1px solid #01B7AB',
        borderRadius: '4px',
        backgroundColor: 'white',
        outline: 'none',
        padding: '0',
        marginLeft: '9px',
    },
    textInputFieldDeadlineName: {
        height: '24px',
        width: '288px',
        border: '1px solid #01B7AB',
        borderRadius: '4px',
        backgroundColor: 'white',
        outline: 'none',
        padding: '0',
        marginLeft: '9px',
    },
    textInputFieldDescription: {
        minHeight: '100px',
        width: '100%',
        border: '1px solid #01B7AB',
        borderRadius: '4px',
        backgroundColor: 'white',
        outline: '0',
        padding: '0',
        marginBottom: '18px'
    },
    calendarButton: {
        height: '32px',
        padding: '4px 11px',
        position: 'relative',
        right: '-10px',
        '&:focus': {
            outline: 'none'
        }
    },
}));

const CreateNewDeadlineForm = ({ dispatch }) => {

    const initialValues = {
        title: '',
        description: '',
        deadline_date: '',
        notify_all: null,
    };

    const RequestSchema = Yup.object().shape({
        deadline_date: Yup.string().required("Required"),
        title: Yup.string().max(300).required('Required'),
        description: Yup.string().required('Required'),
        notify_all: Yup.boolean().required('Required'),
    });

    const handleSubmit = ({ deadline_date, title, description, notify_all }) => {
        const newData = {
            title,
            deadline_date,
            description,
            notify_all: changeType(notify_all)
        }
        console.log(newData)

        axios.post(`/api/keydeadlines`, newData)
            .then(res => {
                dispatch(getKeyDeadlines());
            })
    };

    const changeType = (e) => {
        if (e && typeof e === 'string') {
            if (e.toLowerCase() === 'true') return true;
            if (e.toLowerCase() === 'false') return false;
        }
        return e;
    }

    const classes = useStyles();

    return (
        <Grid container direction='column' className={classes.mainGridContainer}>
            <Grid item style={{ borderBottom: '2px solid #01B7AB', paddingBottom: '14px' }}>
                <Typography color='secondary' className={classes.overTitle} >
                    NEW DEADLINE FORM
                </Typography>
                <Typography className={classes.title}>
                    New Deadline Form
                </Typography>
                <Grid container alignItems='flex-start'>
                    <Typography variant='body1'>
                        Fill in the deadline information and hit the create button.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Formik
                    initialValues={initialValues}
                    validationSchema={RequestSchema}
                    onSubmit={(data, { resetForm }) => { handleSubmit(data), resetForm() }}
                    render={({ values, errors, touched, setFieldValue, handleChange, isValid }) => (
                        <Form>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className={classes.sectionTitle}>
                                        Deadline Details
                                    </Typography>
                                </Grid>
                                <Grid item style={{ width: 'minContent' }}>
                                    <Grid container direction='row'>
                                        <Grid item style={{ marginBottom: '7px' }}>
                                            <Typography variant='body1'>
                                                Deadline date (DD/MM/YY)
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Field name='deadline_date'>
                                                {({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        autoComplete="off"
                                                        dateFormat="dd/mm/yyyy"
                                                        minDate={new Date()}
                                                        value={values.deadline_date}
                                                        onChange={(date) => {
                                                            setFieldValue('deadline_date', moment(date).format(moment.HTML5_FMT.DATE))
                                                        }}
                                                        showDisabledMonthNavigation
                                                        className={classes.textInputFieldDeadlineDate}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item style={{ width: '18px', height: '19px', marginLeft: '9px' }}>
                                            <CalendarIcon />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row'>
                                        <Grid item style={{ marginBottom: '10px' }}>
                                            <Typography variant='body1'>
                                                Deadline name
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Field name='title'>
                                                {({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            className: classes.textInputFieldDeadlineName,
                                                        }}
                                                        value={values.title}
                                                        name="title"
                                                        helperText={(touched.title && !!errors.title) && <span style={{ color: 'red', fontSize: 12 }}>{errors.title}</span>}
                                                        error={(touched.title && !!errors.title) ? true : false}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction='column'>
                                        <Grid item style={{ marginBottom: '7px' }}>
                                            <Typography variant='body1'>
                                                Deadline description
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Field name='description'>
                                                {({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        multiline
                                                        style={{ width: '100%' }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            className: classes.textInputFieldDescription,
                                                            style: {
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'start'
                                                            }
                                                        }}
                                                        value={values.description}
                                                        name="description"
                                                        helperText={(touched.description && !!errors.description) && <span style={{ color: 'red', fontSize: 12 }}>{errors.description}</span>}
                                                        error={(touched.description && !!errors.description) ? true : false}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography className={classes.sectionTitle} >
                                        Notify all users?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field name='notify_all'>
                                        {({ field }) => (
                                            <RadioGroup
                                                // onChange={(e) => changeType(e.target.value)}
                                                value={values.notify_all}
                                                {...field}>
                                                <FormControlLabel
                                                    value='true'
                                                    control={<StyledRadioButton />}
                                                    label="Yes"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value='false'
                                                    control={<StyledRadioButton />}
                                                    label="No"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} style={{ padding: '0', marginTop: '7px' }}>
                                    <Button size='small' color='secondary' variant='contained' type="submit" style={{ marginLeft: '0', width: '152px', height: '32px' }} disabled={!isValid}>CREATE</Button>
                                    <Button size='small' color='secondary' variant='outlined' type='reset' style={{ marginLeft: '18px', width: '152px', height: '32px' }}>CLEAR</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                />
            </Grid>
        </Grid>
    )
}

export default connect()(CreateNewDeadlineForm);