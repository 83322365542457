import React from 'react';
import { Card } from '@material-ui/core';

const RightsDocumentations = () => {
    return (
        <Card style={{ minHeight: 500 }}>
            <div>
                Rights and Documentations
            </div>
        </Card>
    );
};

export default RightsDocumentations;
