import React from 'react';
import {Grid} from "@material-ui/core";
import LatestMatchTicketRequests from "~/Admin/Assets/Approvals/LatestRequests/LatestMatchTicketRequests";
import LatestMerchandiseRequests from "~/Admin/Assets/Approvals/LatestRequests/LatestMerchandiseRequests";

const LatestRequests = ({classes}) => {

    return (
        <>
            <Grid container justifyContent="center"  direction="column" spacing={6}>
                <Grid item container>
                    <LatestMatchTicketRequests classes={classes}/>
                </Grid>
                <Grid item container direction="column">
                    <LatestMerchandiseRequests  classes={classes}/>
                </Grid>
            </Grid>
        </>
    );
};

export default LatestRequests;
