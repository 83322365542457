import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';

import PartnersAutocomplete from "~/Media/Manage/PartnersAutocomplete";
import Dialog from "@material-ui/core/Dialog";
import MuiButton from "~/Components/MUIButton/MUIButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const CommonDelete = ({showDelete, closeModal, deleteItem, name}) => {
    return (
        <Dialog
            PaperProps={{
                style: {
                    maxWidth: 550,
                    width: '100%',
                    borderRadius: 0,
                    padding: 20
                }
            }}
            anchor="right"
            open={showDelete}
            keepMounted
        >
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="subtitle2">Are you sure?</Typography>
                </Grid>

                <Grid item style={{padding: '20px 0'}}>
                    <Typography>{name}</Typography>
                </Grid>

                <Grid item>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <MuiButton
                                fullWidth size="large"
                                onClick={() => {
                                    deleteItem();
                                    closeModal(true)
                                }}
                            >
                                Delete
                            </MuiButton>
                        </Grid>
                        <Grid item>
                            <MuiButton cancel={true} fullWidth size="large" onClick={() => closeModal(true)}>
                                Cancel
                            </MuiButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default CommonDelete;
