import React, { useState } from 'react';
import Button from '~/Components/Button';
import axios from 'axios';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Typography, Grid, makeStyles, CircularProgress } from '@material-ui/core';
import AddFilesToCommentDialog from './AddFilesToCommentDialog/AddFilesToCommentDialog';
import PreviewFile from '~/Components/PreviewFile';
import LibraryApi from '~/services/api/library';

const useStyles = makeStyles((theme) => ({
  input: {
    'border': '1px solid #c6c9db',
    'padding': '8px 15px',
    'borderRadius': 0,
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  openUploadFileDialog: {
    'color': '#af001e',
    'textDecoration': 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const CommentSchema = Yup.object().shape({
  comment: Yup.string()
    .max(1024)
    .required('* required'),
});

export default ({ request, setRefreshRequest }) => {
  const classes = useStyles();
  const initialValues = { comment: '' };
  const [openAddFilesDialog, setOpenAddFilesDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postingCommentError, setPostingCommentError] = useState(null);

  const downloadFile = (f) => {
    const lastDot = f.original_name.lastIndexOf('.');
    const ext = f.original_name.substr(lastDot + 1);
    const name = f.original_name.substr(0, lastDot);

    LibraryApi.downloadImage({ path: f.download, name: name, ext: ext });
  };

  const handleOpenAddFilesDialog = () => {
    setOpenAddFilesDialog(true);
  };

  const handleCloseAddFilesDialog = () => {
    setOpenAddFilesDialog(false);
  };

  const sendFiles = (data) => {
    if (files.length > 0) {
      const dataToAdd = [...files, ...data];
      setFiles(dataToAdd);
      return;
    }

    setFiles(data);
  };

  const handleRemoveFileFromUploads = (item) => {
    const array = files;
    const itemIndex = array.indexOf(item);

    if (array.length === 1) {
      setFiles([]);
      return;
    }

    if (itemIndex > -1) {
      array.splice(itemIndex, 1);
      setFiles([...array]);
    }
  };

  const postComment = (value, resetForm) => {
    setLoading(true);
    setPostingCommentError(null);

    const payload = new FormData();
    payload.append('comment', value.comment);

    for (let i = 0; i < files.length; i++) {
      if (files[i].id) {
        payload.append(files[i].id, '1');
      } else {
        payload.append('files[]', files[i]);
      }
    }

    axios.post(`/api/brand-admin/${request.id}/comment`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      if (res.status === 200) {
        setRefreshRequest(true);
        resetForm();
        setFiles([]);
        setLoading(false);
      }
    }).catch((error) => {
      setPostingCommentError(error);
    });
  };

  const previewUpload = (file) => {
    let name; let path; let media_type;

    if (file.original_name) {
      name = file.original_name;
      path = file.url;

      switch (file.original_name.split('.').pop().toLowerCase()) {
        case 'jpg':
          media_type = 'photos';
          break;
        case 'png':
          media_type = 'photos';
          break;
        case 'pdf':
          media_type = 'pdf';
          break;
        case 'mp4':
          media_type = 'videos';
          break;
        default:
          media_type = 'file';
      }
    } else {
      name = file.name;
      path = URL.createObjectURL(file);

      switch (file.name.split('.').pop().toLowerCase()) {
        case 'jpg':
          media_type = 'photos';
          break;
        case 'png':
          media_type = 'photos';
          break;
        case 'pdf':
          media_type = 'pdf';
          break;
        case 'mp4':
          media_type = 'videos';
          break;
        default:
          media_type = 'file';
      }
    }

    const lastDot = name.lastIndexOf('.');
    const ext = name.substr(lastDot + 1);
    const newName = name.substr(0, lastDot);

    setPreviewFile({ name: newName, path: path, media_type: media_type, ext: ext });
    setShowPreview(true);
  };

  const fileTypeIcon = (fileName) => {
    switch (fileName.split('.').pop().toLowerCase()) {
      case 'jpg':
        return <img style={{ width: 19, height: 19 }} src="/images/icons/SVG/File Type/Image.svg" />;
      case 'png':
        return <img style={{ width: 19, height: 19 }} src="/images/icons/SVG/File Type/Image.svg" />;
      case 'pdf':
        return <img style={{ width: 19, height: 19 }} src="/images/icons/SVG/File Type/PDF.svg" />;
      case 'mp4':
        return <img style={{ width: 19, height: 19 }} src="/images/icons/SVG/File Type/Video.svg" />;
      default:
        return <img style={{ width: 19, height: 19 }} src="/images/icons/SVG/File Type/File.svg" />;
    }
  };

  const formatComment = (comment) => {
    if (!new RegExp(/[\r\n]+/gm).test(comment)) {
      return comment;
    }

    const splitComment = comment.split('\n');

    return splitComment.map((word, key) => {
      return (
        <span key={key}>
          {word}<br />
        </span>
      );
    });
  };
  return (
    <div className="events-block-new cards comments">
      <h4>Comments</h4>
      {request.comments.length > 0 &&
        request.comments.map((item, key) => {
          return (
            <div className="list" key={key}>
              <div>
                <span
                  className="tooltip-requests">{item.user ? item.user.name : ''} - {moment(item.created_at).format('DD/MM/YYYY H:mm')}</span>
              </div>
              <div className="comment-text">
                {formatComment(item.body)}
              </div>

              {
                item.files.length > 0 &&
                <div>
                  {
                    item.files.map((file, key) => (
                      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" alignContent="center" key="key" spacing={2} key={key}>
                        <Grid item>
                          <Grid container spacing={2}>
                            <Grid item>
                              {fileTypeIcon(file.original_name)}
                            </Grid>
                            <Grid item>
                              <Typography style={{
                                whiteSpace: 'nowrap',
                                width: 150,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>
                                {file.original_name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={2}>
                            <Grid item>
                              <img
                                style={{ width: 19, height: 19, cursor: 'pointer' }}
                                src="/images/icons/SVG/View.svg"
                                alt={file.original_name}
                                onClick={() => {
                                  previewUpload(file);
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <img
                                src="/images/icons/SVG/Download.svg"
                                alt="file"
                                style={{ width: 19, height: 19, cursor: 'pointer' }}
                                onClick={() => downloadFile(file)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }
                </div>
              }
            </div>
          );
        })
      }
      {request.status !== 'approved' && request.status !== 'rejected' &&
        <>
          <div>
            <span className="tooltip-requests">Add comment that team can review and respond to</span>
          </div>
          <div>
            <Formik
              onSubmit={(value, { resetForm }) => {
                postComment(value, resetForm);
              }}
              initialValues={initialValues}
              validationSchema={CommentSchema}
              render={({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <Field
                    name="comment"
                    type="text"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Comment"
                        margin="normal"
                        fullWidth
                        multiline={true}
                        rows={6}
                        InputProps={{ className: classes.input }}
                        variant="outlined"
                        error={touched.comment && !!errors.comment}
                        helperText={touched.comment && !!errors.comment ? '* required' : ''}
                      />
                    )}
                  />
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Grid container direction="row" alignContent="center" spacing={1}>
                        {
                          files.length > 0 &&
                          <Grid item>
                            <img style={{ width: 19, height: 19, filter: 'invert(6%) sepia(94%) saturate(7109%) hue-rotate(346deg) brightness(109%) contrast(102%)' }} src="/images/icons/SVG/File Type/File.svg" alt="file" />
                          </Grid>
                        }
                        <Grid item>
                          <Typography className={classes.openUploadFileDialog} onClick={handleOpenAddFilesDialog}>{files.length > 0 ? 'View attached documents' : 'Attach a document'}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {
                        loading ?
                          <CircularProgress color="secondary" /> :
                          <div className="comment-btn-wrapper">
                            <Button class="color-red noMarg" text="ADD COMMENT" type="submit" />
                          </div>
                      }
                    </Grid>
                    {
                      postingCommentError &&
                      <Grid item>
                        <p>{postingCommentError}</p>
                      </Grid>
                    }
                  </Grid>
                </Form>
              )}
            />
          </div>
        </>
      }
      <AddFilesToCommentDialog
        openAddFilesDialog={openAddFilesDialog}
        handleCloseAddFilesDialog={handleCloseAddFilesDialog}
        sendFiles={sendFiles}
        handleRemoveFileFromUploads={handleRemoveFileFromUploads}
        previewUpload={previewUpload}
        fileTypeIcon={fileTypeIcon}
        files={files}
      />
      <PreviewFile
        previewFile={previewFile}
        showPreview={showPreview}
        setShowPreview={() => setShowPreview(false)}
      />
    </div>
  );
};
