import React, { useCallback, useEffect, useState } from 'react';
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Check, Close } from "@material-ui/icons";
import { CircularProgress, TextField, Typography, Grid, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { debounce } from "lodash";

const useStyle = makeStyles((theme) => ({
    autocomplete: {
        height: 30,
        minWidth: 200,
        paddingTop: '0 !important',
        backgroundColor: '#fff',
        border: 0,
        borderRadius: 0,
        fontSize: 14,
        color: '#3e3f60',
    },
    endAdornment: {
        display: 'none',
    },
    input: {
        paddingTop: '6px !important',
    }
}));

const MembersAllocation = (
    {
        request,
        setRefreshRequest,
        manualMode,
        refreshRequest,
        dispatch,
        deleteMemberExecute,
        memberToDelete
    }) => {

    const memberType = request.teamAsset.access_type;
    const classes = useStyle();
    const [members, setMembers] = useState([]);

    useEffect(() => {
        if (manualMode) {
            getMembers();
        }
    }, [manualMode, request]);

    const getMembers = (search = '') => {

        const teamId = request.teamAsset.team_id;

        const searchParam = search ? '&name=' + search : '';

        let uri = request.teamAsset.access_type === 'players'
            ? `/api/team-players?request_id=${request.id}&team_id=${teamId}${searchParam}`
            : `/api/team-staff?request_id=${request.id}&team_id=${teamId}${searchParam}`;

        axios.get(uri).then((resp) => {
            setMembers(resp.data.data);
        });

    }

    const searchMembersHandler = (value, reason) => {

        if (reason === 'clear' || reason === 'input') {
            getMembers(value)
        }
    }

    const debouncedOnInput = debounce(searchMembersHandler, 300);

    const inputHandler = useCallback((value, reason) => {
        debouncedOnInput(value, reason);
    }, []);

    const searchMembers = (event, value, reason) => {
        inputHandler(value, reason);
    }

    useEffect(() => {
        if (deleteMemberExecute) {
            let data = { member_type: memberType, member_id: memberToDelete.id };
            setRefreshRequest(false);

            axios.put(`/api/talent-access-request/${request.id}/remove-member`, data).then(() => {
                setRefreshRequest(true);
                dispatch({ type: 'talent_access_admin/SET_MEMBER_DELETE_EXECUTE', payload: false });
                dispatch({ type: 'talent_access_admin/SET_MEMBER_TO_DELETE', payload: null });
                dispatch({ type: 'talent_access_admin/SET_DELETE_MODE', payload: false });
            })
        }
    }, [deleteMemberExecute])

    const allocationManagement = (member, actionType = '') => {
        if (!manualMode) {
            return false;
        }

        if (!actionType && member.pivot?.user_requested) {
            actionType = 'allocate'
        }

        if (!actionType && !member.pivot?.user_requested) {
            dispatch({ type: 'talent_access_admin/SET_MANUAL_DIALOG_SHOW', payload: true });
            dispatch({ type: 'talent_access_admin/SET_DELETE_MODE', payload: true });
            dispatch({ type: 'talent_access_admin/SET_MEMBER_TO_DELETE', payload: member });
            return false;
        }

        let data = { member_type: memberType, member_id: member.id };
        setRefreshRequest(false);

        axios.put(`/api/talent-access-request/${request.id}/${actionType}`, data).then(() => {
            setRefreshRequest(true);
        })
    }

    return (
        <div className="events-block-new cards">
            <Grid container direction="column">
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <h4 className="tabbed-content-title">{memberType === 'players' ? 'Player Allocation' : 'Staff Allocation'}</h4>
                        </Grid>
                        <Grid item>
                            {refreshRequest &&
                                <div className="media-loader-container">
                                    <CircularProgress color="secondary" className="loaded-content h-100" />
                                </div>
                            }
                            <Grid container direction="row" spacing={2}>
                                <Grid item md={6}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <h4 className="tabbed-content-subtitle additional">Requested {memberType === 'players' ? 'Players' : 'Staff'}</h4>
                                        </Grid>
                                        {request[memberType].map((member, key) => {
                                            let allocated = Boolean(member.pivot?.allocated);
                                            return (
                                                <Grid item key={key} className="record">
                                                    <div className="events-block-new cards">
                                                        <Grid container direction="row" spacing={1}>
                                                            <Grid item>
                                                                <ToggleButton
                                                                    value="check"
                                                                    selected={allocated}
                                                                    onChange={() => allocationManagement(member)}
                                                                    style={{
                                                                        backgroundColor: allocated ? '#af001e' : 'rgba(62, 63, 96, 0.25)',
                                                                        borderRadius: '50%',
                                                                        padding: 0,
                                                                        border: 'none',
                                                                        height: 18,
                                                                        width: 18,
                                                                    }}>
                                                                    <Check style={{
                                                                        color: 'white',
                                                                        width: 14,
                                                                        height: 14
                                                                    }} />
                                                                </ToggleButton>
                                                            </Grid>
                                                            <Grid item>
                                                                <ToggleButton
                                                                    value="check"
                                                                    selected={!allocated}
                                                                    style={{
                                                                        backgroundColor: !allocated ? '#af001e' : 'rgba(62, 63, 96, 0.25)',
                                                                        borderRadius: '50%',
                                                                        padding: 0,
                                                                        border: 'none',
                                                                        height: 18,
                                                                        width: 18,
                                                                    }}>
                                                                    <Close style={{
                                                                        color: 'white',
                                                                        width: 14,
                                                                        height: 14,
                                                                        cursor: 'default',
                                                                    }} />
                                                                </ToggleButton>
                                                            </Grid>

                                                            <Grid item>&nbsp;{member.name}</Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            )
                                        }
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    {manualMode &&
                                        <Grid container direction="column">
                                            <Grid item>
                                                <h4 className="tabbed-content-subtitle additional">Additional {memberType === 'players' ? 'Players' : 'Staff'}</h4>
                                            </Grid>
                                            <Grid item className="record">
                                                <div className="events-block-new cards">
                                                    <Grid container direction="row" spacing={1}>
                                                        <Grid item md={1}>
                                                            <ToggleButton
                                                                value="check"
                                                                selected={false}
                                                                style={{
                                                                    backgroundColor: 'rgba(62, 63, 96, 0.25)',
                                                                    borderRadius: '50%',
                                                                    padding: 0,
                                                                    border: 'none',
                                                                    height: 18,
                                                                    width: 18,
                                                                }}>
                                                                <Check style={{
                                                                    color: 'white',
                                                                    width: 14,
                                                                    height: 14
                                                                }} />
                                                            </ToggleButton>
                                                        </Grid>
                                                        <Grid item md={11}>
                                                            {!refreshRequest &&
                                                                <Autocomplete
                                                                    debug={true}
                                                                    classes={{
                                                                        inputRoot: classes.autocomplete,
                                                                        endAdornment: classes.endAdornment,
                                                                        input: classes.input
                                                                    }}
                                                                    options={members}
                                                                    onChange={(event, value) => {
                                                                        if (value && value.id) {
                                                                            allocationManagement(value, 'add-member');
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                    disableCloseOnSelect={true}
                                                                    onInputChange={searchMembers}
                                                                    filterOptions={(options, state) => options}
                                                                    getOptionLabel={(option) => option.name}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} variant="outlined" label=""
                                                                            placeholder={memberType === 'staff' ? 'Type a new staff...' : 'Type a new player...'}
                                                                        />
                                                                    )}
                                                                />
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <Typography className="help-text">
                                                    Please use this space to allocate additional players to the ones not
                                                    already requested.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = ((state) => ({
    deleteMemberExecute: state.talentAccessAdminRequest.deleteMemberExecute,
    memberToDelete: state.talentAccessAdminRequest.memberToDelete,
    manualMode: state.talentAccessAdminRequest.manualMode,
}));
export default connect(mapStateToProps)(MembersAllocation);
