import React from 'react';
import moment from 'moment';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import LibraryApi from '~/services/api/library';
import ToggleButton from '@material-ui/lab/ToggleButton';

import {Check} from '@material-ui/icons';
import {Typography} from '@material-ui/core';


export default ({request, setShowPreview, setPreviewFile}) => {
  const count = request.files && request.files.length;

  const fileLists =
        request.files &&
        request.files.reduce((acc, file) => {
          if (!acc[file.version]) {
            acc[file.version] = [];
          }
          acc[file.version] = acc[file.version].concat(file);
          return acc;
        }, {});

  const downloadAll = () => {
    axios.get(`/api/brand-request/download-all/${request.id}`, {responseType: 'blob'}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'request_files.zip');
      document.body.appendChild(link);
      link.click();
    }).catch(function(error) {
      console.log(error);
    });
  };
  let members = [];

  if (request.type === 'talent') {
    members = request[request.teamAsset?.access_type];
  }

  const downloadFile = (f) => {
    const lastDot = f.original_name.lastIndexOf('.');
    const ext = f.original_name.substr(lastDot + 1);
    const name = f.original_name.substr(0, lastDot);

    LibraryApi.downloadImage({path: f.download, name: name, ext: ext});
  };

  return (
    <Grid container className="events-block-new cards fill">
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <Grid container justifyContent="space-between" direction="column">
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <h4>Request Details</h4>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {
                            request.type === 'approvals' ?
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container direction="row" justifyContent="space-between">
                                      <Grid item>
                                        <Typography style={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                          lineHeight: 1.57,
                                          color: '#999bb5',
                                        }}>REQUEST TYPE</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography>{request.request_type?.name}</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {
                                    !!request.request_type?.other && (
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Grid container direction="row" justifyContent="space-between">
                                          <Grid item>
                                            <Typography style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                              lineHeight: 1.57,
                                              color: '#999bb5',
                                            }}>CUSTOM REQUEST TYPE</Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography>{request.other_request_type}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )
                                  }
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container direction="row" justifyContent="space-between">
                                      <Grid item>
                                        <Typography style={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                          lineHeight: 1.57,
                                          color: '#999bb5',
                                        }}>DEADLINE</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography>{moment(request.deadline, 'DD-MM-YYYY').format('DD/MM/YYYY')}</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid> :
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container direction="row" justifyContent="space-between">
                                      <Grid item>
                                        <Typography style={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                          lineHeight: 1.57,
                                          color: '#999bb5',
                                        }}>ACCESS TYPE</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className="text-capitalize">{request.teamAsset?.access_type} Access</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container direction="row" justifyContent="space-between">
                                      <Grid item>
                                        <Typography style={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                          lineHeight: 1.57,
                                          color: '#999bb5',
                                        }}>{request.teamAsset?.access_type === 'players' ? 'PLAYER NAME' : 'STAFF NAME'}</Typography>
                                      </Grid>
                                      <Grid item>
                                        {
                                          members && members.length > 0 &&
                                                    members.map((member, key) => {
                                                      const allocated = Boolean(member.pivot?.allocated);
                                                      return (
                                                        <Grid container key={key} direction="column">
                                                          <Grid item>
                                                            <Grid container direction="row" spacing={1}>
                                                              <Grid item>
                                                                <ToggleButton
                                                                  value="check"
                                                                  selected={allocated}
                                                                  style={{
                                                                    backgroundColor: allocated ? '#af001e' : 'rgba(62, 63, 96, 0.25)',
                                                                    borderRadius: '50%',
                                                                    padding: 0,
                                                                    border: 'none',
                                                                    height: 18,
                                                                    width: 18,
                                                                  }}>
                                                                  <Check style={{
                                                                    color: 'white',
                                                                    width: 14,
                                                                    height: 14,
                                                                    cursor: 'default',
                                                                  }} />
                                                                </ToggleButton>
                                                              </Grid>
                                                              <Grid item>{member.name}</Grid>
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      );
                                                    })
                                        }
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container direction="row" justifyContent="space-between">
                                      <Grid item>
                                        <Typography style={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                          lineHeight: 1.57,
                                          color: '#999bb5',
                                        }}>TIME REQUIRED</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography>{request.teamAsset?.time}h</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {
                                    request.teamAsset?.request_type === 'partner event' &&
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <Grid container direction="row" justifyContent="space-between">
                                            <Grid item>
                                              <Typography style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                lineHeight: 1.57,
                                                color: '#999bb5',
                                              }}>PROPOSED DATES</Typography>
                                            </Grid>
                                            <Grid item>
                                              {
                                                request.propositions.map((proposition, key) => {
                                                  return (
                                                    <Grid item key={key}>
                                                      {moment(proposition.event_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                                    </Grid>
                                                  );
                                                })
                                              }
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                  }
                                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <Grid container direction="column">
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <h4>{request.type === 'approvals' ? 'Creative Files' : 'Supporting Documents'}</h4>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {
              Object.keys(fileLists).map((version, index) => {
                return (
                  <React.Fragment key={index + 'ver'}>
                    {
                      fileLists[version] &&
                                            fileLists[version].map((f, i) => (
                                              <Grid container direction="row" alignItems="center" justifyContent="space-between" key={i + 'o_name'} className={(count > i + 1) ? 'supporting-doc' : 'supporting-doc last'}>
                                                <Grid item>
                                                  <Grid container direction="row">
                                                    <Grid item className="icon-wrapper">
                                                      <img src="/images/icons/SVG/File Type/Image.svg" alt="file" />
                                                    </Grid>
                                                    <Grid item className="filename p-0 text-left" title={f.original_name}>
                                                      {f.original_name.length > 20 ? f.original_name.substring(0, 20) + '...' : f.original_name}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item>
                                                  <Grid container direction="row">
                                                    <Grid item className="icon-wrapper p-0 text-right">
                                                      {
                                                        (f.media_type === 'photos' || f.media_type === 'videos') &&
                                                                    <img src="/images/icons/SVG/View.svg" className="view" onClick={() => {
                                                                      const lastDot = f.original_name.lastIndexOf('.');
                                                                      const ext = f.original_name.substr(lastDot + 1);
                                                                      const name = f.original_name.substr(0, lastDot);

                                                                      const file = {...f, name: name, path: f.url, ext: ext};
                                                                      setPreviewFile(file);
                                                                      setShowPreview(true);
                                                                    }} />
                                                      }
                                                    </Grid>
                                                    <Grid item className="p-0 text-right">
                                                      <a target="_blank" onClick={() => {
                                                        downloadFile(f);
                                                      }}>
                                                        <span className="icon-wrapper m-0">
                                                          <img src="/images/icons/SVG/Download.svg" className="download-icon" />
                                                        </span>
                                                      </a>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            ))
                    }
                  </React.Fragment>
                );
              })
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div>
          {
            count > 1 &&
                        <span className="download-text" onClick={downloadAll}>Download all</span>
          }
        </div>
      </Grid>
    </Grid>
  );
};
