import React, {useState} from "react";
import { withStyles, Grid } from "@material-ui/core";
import RequestDetails from "./RequestDetails";
import axios from "axios";

import './index.scss';
import SubmissionDetails from "~/Admin/RequestManagement/TalentAccessRequest/SubmissionDetails";
import CommentsForm from "~/BrandApprovalRequest/CommentsForm";
import RequestActions from "~/BrandApprovalRequest/RequestActions";
import Button from "~/Components/Button";
import PreviewFile from "~/Components/PreviewFile";
import Description from "~/Admin/RequestManagement/TalentAccessRequest/Description";
import MembersAllocation from "~/Admin/RequestManagement/TalentAccessRequest/MembersAllocation";
import ManualAllocationBar from "~/Admin/RequestManagement/TalentAccessRequest/ManualAllocationBar";
import ManualDialog from "~/Admin/RequestManagement/TalentAccessRequest/ManualDialog/ManualDialog";
import {Tabs, Tab, Box, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {resetTalentAccessAdminActions} from "~/Admin/RequestManagement/TalentAccessRequest/talentAccessAdminActions";

const styles = theme => ({});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const mapStateToProps = ((state) => ({
    manualDialogShow: state.talentAccessAdminRequest.manualDialogShow,
    manualMode: state.talentAccessAdminRequest.manualMode,
}));


export default connect(mapStateToProps)(withStyles(styles)(props => {
    const [request, setRequest] = React.useState();
    const [refreshRequest, setRefreshRequest] = React.useState(true);
    const [showEdit, setShowEdit] = React.useState(false);
    const [showPreview, setShowPreview] = React.useState(false);
    const [previewFile, setPreviewFile] = React.useState(false);
    const [tabValue, setTabValue] = useState(0);

    React.useEffect(() => {
        props.dispatch({type: 'talent_access_admin/SET_MANUAL_MODE', payload: false});
    }, []);

    React.useEffect(() => {
        if (refreshRequest) {
            axios
                .get(`/api/brand-request/${props.match.params.id}`)
                .then(res => {
                    setRefreshRequest(false);
                    setShowEdit(false);
                    setRequest(res.data.data);
                    props.dispatch(resetTalentAccessAdminActions());

                }).catch((error) => {
                    if (error.response && error.response.status === 404) {
                        props.history.push('/404')
                    }
                });
        }
    }, [refreshRequest]);

    const setLoaded = () => {

    }

    const handleTabs = (event, newValue) => {
        setTabValue(newValue);
    }

    return (
        <section className="talent-access-request-admin-details sticky-footer">
            {props.adminMode ? <Grid container direction="row">
                <Grid item>
                    <span>
                        <Button onClick={props.history.goBack} class="btn-grey footer-downloads noMarg" text="Back" />
                    </span>
                </Grid>
            </Grid> : <Grid container direction="row">
                    <Grid item>
                        <span>
                            <Button onClick={props.history.goBack} class="btn-grey footer-downloads noMarg" text="Back" />
                        </span>
                    </Grid>
                </Grid>}
            <Grid container direction="row" spacing={2}>
                <Grid item md={12}>
                    <div className="bodyContent">
                        <div className="request-title">{request &&
                            request.name
                                ?
                                <h1>{`${request.id}: ${request.name}`}</h1>
                                :
                                <h1>{request ? request.id : ''}</h1>
                        }
                        </div>

                        {request && (
                            <>{showEdit &&

                                <section className="approvals-content">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <SubmissionDetails request={request} />
                                        </div>
                                    </div>
                                </section>
                            }
                                {!showEdit &&
                                    <>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item lg={3} md={3} className="request-sections">
                                                <Grid container direction="column">
                                                    <Grid item  style={{marginBottom: 10}}>
                                                        <SubmissionDetails request={request} />
                                                    </Grid>
                                                    <Grid item>
                                                        <RequestDetails request={request} />
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                            <Grid item lg={9} md={9} className="request-sections">
                                                <Grid container direction="column" wrap="nowrap">
                                                    <Grid item>
                                                        <div className="events-block-new cards tabs">
                                                            <Tabs value={tabValue} onChange={handleTabs} aria-label="Submission details">
                                                                <Tab className={"submission-tabs" + (tabValue === 0 ? ' active' : '')} label="Submission Details" {...a11yProps(0)} />
                                                                <Tab className={"submission-tabs" + (tabValue === 1 ? ' active' : '')} label="Submission Justification" {...a11yProps(1)} />
                                                                <Tab className={"submission-tabs" + (tabValue === 2 ? ' active' : '')} label="Comments" {...a11yProps(2)} />
                                                            </Tabs>
                                                        </div>
                                                    </Grid>
                                                    <TabPanel value={tabValue} index={0}>
                                                        <MembersAllocation
                                                            request={request}
                                                            refreshRequest={refreshRequest}
                                                            setRefreshRequest={setRefreshRequest}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel value={tabValue} index={1}>
                                                        <Description request={request} setShowPreview={setShowPreview} setPreviewFile={setPreviewFile}/>
                                                    </TabPanel>
                                                    <TabPanel value={tabValue} index={2}>
                                                        <CommentsForm request={request} setRefreshRequest={setRefreshRequest} />
                                                    </TabPanel>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        {!props.manualMode &&
                                            <Grid container direction="column" style={{marginTop: 32, marginBottom: 10}} justifyContent="space-around">
                                                <Grid item>
                                                    <ManualAllocationBar />
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                }

                                <Grid container direction="row" spacing={3} >
                                    <Grid item lg={3} md={3} />
                                    <Grid item lg={9} md={9} className="request-sections">
                                        <RequestActions adminMode={props.adminMode}
                                            request={request}
                                            setRequest={setRequest}
                                            setShowEdit={setShowEdit}
                                            showEdit={showEdit}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
            <PreviewFile
                previewFile={previewFile}
                showPreview={showPreview}
                setLoaded={setLoaded}
                setShowPreview={() => setShowPreview(false)}
            />
            {props.manualDialogShow &&
                <ManualDialog />
            }

        </section>
    );
}));
