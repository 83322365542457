import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    makeStyles,
    Grid,
    Divider
} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import AdminMenu from "../AdminMenu";
import RequestsList from "~/Admin/RequestManagement/RequestsList/RequestsList";

const RequestManagement = (props) => {

    const useStyles = makeStyles((theme) => ({
        section: {
            // padding: '2em',
        },
        sectionTitle: {
            textTransform: 'capitalize',
            fontWeight: 600,
            color: '#3e3f60',
            fontSize: 40,
        },
        indicator: {
            color: '#af001e'
        },
        tourimg: {
            maxWidth: '-webkit-fill-available'
        },
        table: {
            minWidth: 650,
        },
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60
        },
        textPadding: {
            paddingTop: 20
        },
        arrowIcon: {
            width: 29,
            height: 29,
        },
        tabItem: {
            color: '#af001e',
            fontWeight: 'bold'
        },
        tabLink: {
            borderBottom: '2px solid #af001e',
        },
        tabItemBlack: {
            color: 'black'
        },
        newUserButton: {
            borderRadius: 0,
            backgroundColor: '#af001e',
            color: 'white',
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },
        tabs: {
            display: 'block',
            left: 0,
        },
        tab: {
            alignItems: 'flex-start',
            textTransform: 'capitalize',
        },
        activeTab: {
            alignItems: 'flex-start',
            textTransform: 'capitalize',
            color: '#af001e',
            fontWeight: 'bold'
        },
        titlePadding: {
            paddingTop: '2rem'
        },
        test: {
            width: 500
        },
        createUserPadding: {
            padding: 20,
            minWidth: 400
        },
        closeButton: {
            cursor: 'pointer'
        },
        createUserButton: {
            backgroundColor: '#af001e',
            color: 'white',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: '#af001eA5',
            }
        },
        cancelUserButton: {
            backgroundColor: '#d8dae6',
            color: '#3e3f60',
            borderRadius: 0
        },
        subtitle: {
            color: '#af001e'
        },
        dividerLink: {
            backgroundColor: '#af001e'
        },
        dialog: {
            borderRadius: 0,
            maxWidth: 490
        },
        timelineItem: {
            '&&&:before': {
                content: '""',
                flex: 1,
                padding: 0,
            },
        },
        reviewButton: {
            backgroundColor: '#af001e',
            color: 'white',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: '#af001eA5',
            }
        }
    }));

    const classes = useStyles();

    return (
        <section className={classes.section + ' brand-approval brand-approval-requests sticky-footer brand-approval-requests-title'}>
            <Grid container>
                <Grid item>
                    <h1 className={classes.sectionTitle}>Admin</h1>
                </Grid>
            </Grid>
            <Grid container>
                <AdminMenu/>
            </Grid>
            <Divider />
            <Grid container style={{ marginTop: '2em' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RequestsList adminMode={true} />
                </Grid>
            </Grid>
        </section>
    );
}


export default connect(null, null)(withRouter(RequestManagement));
