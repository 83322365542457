import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {assignUsersToRole, fetchActiveRole, setRole, openEditRoleDialog, setEditRoleType} from "./roleActions";
import {connect} from "react-redux";
import {getRoles} from "../userActions";
import RoleDetails from "./RoleDetails/RoleDetails";
import EditPartnerUsersDialog from "~/Admin/PartnerManagement/EditPartnerUsersDialog/EditPartnerUsersDialog";
import ExpandedPermissions from "./ExpandedPermissions/ExpandedPermissions";
import CreateRole from "./CreateRole/CreateRole";
import EditRole from "./EditRole/EditRole";
import Permitted from "~/Permissions/Permitted";
import {filterUsers} from "~/Admin/UserManagements/RoleManagementTab/roleActions";
import AdminFilter from "~/Admin/AdminFilter";
import MuiButton from "~/Components/MUIButton/MUIButton";
import AdminPagination from "~/Admin/AdminPagination";

const RoleManagementTab = (props) => {
    const {
        classes,
        roles,
        dispatch,
        users,
        meta,
        usersMeta,
        activeRole,
        criteria,
    } = props;

    const [editPartnerUsersDialog, setEditPartnerUsersDialog] = useState(false);
    const [userList, setUserList] = useState([]);
    const [showRoleDetails, setShowRoleDetails] = useState(false);

    const [createRole, setCreateRole] = useState(false);

    const [page, setPage] = useState(1);

    const [filters, setFilters] = useState({
        search: '',
        sort: 'name',
        perPage: 5,
    });


    useEffect(() => {
        props.dispatch(getRoles(filters, filters));
    }, [page, filters]);

    useEffect(() => {
        if (activeRole) {
            let userIds = activeRole.users.map(user => user.id);
            setUserList(userIds)
        }
    }, [activeRole]);

    const handleActivePartnerUserList = (userList) => {
        setUserList(userList);
    };

    const handleSaveUsers = () => {
        props.dispatch(assignUsersToRole(activeRole.id, userList))
            .then(() => {
                setEditPartnerUsersDialog(false);
                dispatch(getRoles({criteria, input: ''}, null));
            });
    }

    const handleEditPartnerUsersDialogClose = () => {
        setEditPartnerUsersDialog(false);
    };

    // TOGGLE EDIT ROLE SIDENAV
    const handleOpenEditRoleDialog = (role) => {
        if (role) {
            dispatch(fetchActiveRole(role.id));
            dispatch(setEditRoleType(role.role_type));
            dispatch(openEditRoleDialog(true));
        }
    }

    const toggleCreateRole = () => {
        setCreateRole(!createRole);
    }

    const toggleRoleDetails = (role) => {
        if (role) {
            dispatch(fetchActiveRole(role.id));
            setShowRoleDetails(true);
        } else {
            dispatch(setRole());
            setShowRoleDetails(false)
        }
    }

    const goToPage = (page) => {
        setPage(page);
        dispatch(getRoles({criteria, input: ''}, null, page))
    }

    return (
        <>
            <Card style={{padding: 20, borderRadius: 10}}>

                <Typography className={classes.typeTitle}>Roles</Typography>

                <Grid container direction="row">
                    <Grid item xs={2}>
                        <Typography style={{color: '#083D49', fontSize: 16}}>SELECT ROLES(S)</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <AdminFilter values={filters} onChange={setFilters} sortFields={{
                            'name': 'Name',
                            'type': 'Type',
                        }} />
                    </Grid>
                    <Grid item xs={2}>
                        <Permitted one="create_new_role">
                            <MuiButton
                                fullWidth
                                onClick={toggleCreateRole}>
                                ADD NEW ROLE
                            </MuiButton>
                        </Permitted>
                    </Grid>
                </Grid>

                <Grid container direction="column">
                    <Grid item>
                        <Table>
                            <TableHead style={{borderBottom: '2px solid #01B7AB'}}>
                                <TableRow>
                                    <TableCell>
                                        <Typography color="secondary">Role ID</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="secondary">Name</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="secondary">Associated User</Typography>
                                    </TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roles ? roles.map((role) => (
                                        <TableRow key={role.id}>
                                            <TableCell><Typography>{role.id}</Typography></TableCell>
                                            <TableCell><Typography>{role.display_name}</Typography></TableCell>
                                            <TableCell><Typography>{role.users_count}</Typography></TableCell>
                                            <TableCell align="center" width={50}>
                                                <Grid container direction="column">
                                                    <Permitted one="edit_existing_role">
                                                        <Grid item>
                                                            <Typography style={{cursor: 'pointer'}}
                                                                    color="secondary" onClick={() => {
                                                                handleOpenEditRoleDialog(role);
                                                            }}>Edit</Typography>
                                                        </Grid>
                                                    </Permitted>
                                                    <Grid item>
                                                        <Typography style={{cursor: 'pointer'}}
                                                                    color="secondary" onClick={() => {
                                                            toggleRoleDetails(role);
                                                        }}>Open</Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow>
                                        <TableCell component="th" scope="row" colSpan={6}>
                                            <Typography>No results for given
                                                criteria</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item>
                        <Grid item md={12} className={classes.paginationWrapper}>
                            <AdminPagination meta={meta} setPage={setPage} />
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            <ExpandedPermissions
                createRole={createRole}
                activeRole={activeRole}
                toggleRoleDetails={toggleRoleDetails}
            />

            <CreateRole
                {...{
                    createRole,
                    toggleCreateRole
                }}
            />

            <EditRole
                {...{
                    activeRole
                }}
            />

            <RoleDetails
                showRoleDetails={showRoleDetails}
                toggleRoleDetails={toggleRoleDetails}
                setEditPartnerUsersDialog={setEditPartnerUsersDialog}
            />

            <EditPartnerUsersDialog
                active_partner_data={activeRole}
                meta={usersMeta}
                {...{
                    handleSaveUsers,
                    users,
                    userList,
                    handleActivePartnerUserList,
                    editPartnerUsersDialog,
                    filterUsers,
                    handleEditPartnerUsersDialogClose
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    meta: state.user.roles.meta,
    roles: state.user.roles.data,
    activityLogs: state.user.activityLogs.data,
    lastActivity: state.user.activityLogs.last_activity,
    partners: state.partner.partners.data,
    loggedInUser: state.user.user,
    activeRole: state.role.role,
});

export default connect(mapStateToProps)(RoleManagementTab);
