import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import CalendarIcon from "../../Components/Icons/CalendarIcon";
import moment from "moment";

const DatePickerMui = ({ label, style, name, ...otherProps }) => {
  const useStyles = makeStyles({
    datePicker: {
      border: "1px solid #01B7AB",
      borderRadius: "4px",
      padding: "0 10px",
      cursor: "pointer",
    },
    customStyles: style,
  });

  const classes = useStyles();
  return (
    <Grid item>
      <Grid container alignItems="center" style={{ marginBottom: "5px" }}>
        <Typography>{label}</Typography>
        <Grid item>
          <Grid container alignItems="center">
            <Field name={name}>
              {({ field, form }) => {
                return (
                  <DatePicker
                    id={name}
                    autoComplete="off"
                    {...field}
                    {...otherProps}
                    dateFormat="DD/MM/YYYY"
                    minDate={new Date()}
                    value={field.value}
                    onChange={(e) => {
                      form.setFieldValue(name, moment(e).format('DD/MM/YYYY'));
                    }}
                    showDisabledMonthNavigation
                    className={`${classes.datePicker} ${
                      style ? classes.customStyles : ""
                    }`}
                  />
                );
              }}
            </Field>
            <Grid item style={{ width: "18px", height: "19px" }}>
              <CalendarIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ErrorMessage name={name}>
        {(errorMsg) => (
          <span style={{ color: "red", fontSize: 12 }}>{errorMsg}</span>
        )}
      </ErrorMessage>
    </Grid>
  );
};

export default DatePickerMui;
