import React from "react";
import { FiberManualRecord } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { DropdownButton, Dropdown } from "react-bootstrap";
import qs from 'querystring';

import axios from "axios";
import './list.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';

import {
    CircularProgress,
    Grid,
    makeStyles,
} from "@material-ui/core";
import FiltersIconWhite from "~/Components/Icons/FiltersIconWhite";
import FiltersIconBlack from "~/Components/Icons/FiltersIconBlack";
import { withRouter } from "react-router";
import momentNative from "moment";
import Typography from "@material-ui/core/Typography";
import ApprovalRequestSidebar
    from "~/Admin/RequestManagement/RequestsList/ApprovalRequestSidebar/ApprovalRequestSidebar";

import TalentRequestList from "~/Admin/RequestManagement/RequestsList/TalentRequestList/TalentRequestList";
import ApprovalsRequestList from "~/Admin/RequestManagement/RequestsList/ApprovalsRequestList/ApprovalsRequestList";
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({

    tableCard: {
        marginTop: 10
    },
    tableHeaderBorder: {
        borderBottom: '2px solid #af001e',
    },
    tableCell: {
        whiteSpace: 'nowrap'
    },
    pendingChip: {
        backgroundColor: '#d8dae64D',
        color: '#3e3f60'
    },
    awaitChip: {
        backgroundColor: '#ebae451A',
        color: '#ebae45'
    },
    approvedChip: {
        backgroundColor: '#44de6f1A',
        color: '#44de6f'
    },
    rejectedChip: {
        backgroundColor: '#af001e1A',
        color: '#af001e'
    },
    card: {
        padding: '0',
        height: "100%",
        borderRadius: 0
    },
    autocomplete: {
        height: 40,
        minWidth: 200,
        paddingTop: '0 !important',
        backgroundColor: '#fff',
        border: 0,
        boxShadow: '0 0 4px 0 rgba(62, 63, 96, 0.15)',
        borderRadius: 0,
        fontSize: 14,
        color: '#3e3f60',
    },
}));


const RequestsList = (props) => {

    const {
        adminMode,
        user
    } = props;

    const [request_types, setRequestTypes] = React.useState([]);
    const [meta, setMeta] = React.useState([]);
    const [showFilter, setShowFilter] = React.useState(false);
    const [requests, setRequests] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [sortBy, setSortBy] = React.useState('updated_at');
    const [perPage, setPerPage] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [requestType, setRequestType] = React.useState(null);
    const [requestStatus, setRequestStatus] = React.useState(null);
    const [dateFrom, setDateFrom] = React.useState(null);
    const [dateTo, setDateTo] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);
    const [tabRequestType, setTabRequestType] = React.useState('approvals');
    const [talentRequestType, selectTalentRequestType] = React.useState();

    const classes = useStyles();

    const checker = new PermissionChecker(user)

    const location = props.location;
    let queryParams = location.search[0] === '?' ? qs.parse(location.search.slice(1)) : qs.parse(location.search);

    const loadData = () => {

        let params = {
            page: queryParams.page || page,
            perPage: queryParams.perPage || perPage,
            sortBy: queryParams.sortBy || sortBy,
        };

        if (queryParams.search) {
            params.name = queryParams.search;
        }

        if (queryParams.userName) {
            params.userName = queryParams.userName;
        }

        if (queryParams.requestType) {
            params.request_type_id = queryParams.requestType;
        }

        if (checker.one('admin_review_approvals_request')) {
            params.type = queryParams.type || 'approvals';
        } else {
            params.type = checker.one('admin_review_approvals_request') ? 'approvals' : 'talent';
        }

        setTabRequestType(params.type);

        if (queryParams.requestStatus) {
            params.status = queryParams.requestStatus;
        }

        if (queryParams.partner) {
            params.partner = queryParams.partner;
        }
        if (queryParams.playerQty) {
            params.playerQty = queryParams.playerQty;
        }

        if (queryParams.teamId) {
            params.teamId = queryParams.teamId;
        }

        if (queryParams.teamAssetName) {
            params.teamAssetName = queryParams.teamAssetName;
        }

        if (queryParams.accessType && queryParams.talentRequestType) {
            params.accessType = queryParams.accessType;
            params.talentRequestType = queryParams.talentRequestType;
            selectTalentRequestType(params.accessType === 'players' ? 'player-commercial' : params.talentRequestType);
        }

        if (queryParams.allocTime) {
            params.allocTime = queryParams.allocTime;
        }

        if (queryParams.dateFrom) {
            params.date_from = queryParams.dateFrom;
        }

        if (queryParams.dateTo) {
            params.date_to = queryParams.dateTo;
        }

        if (queryParams.search
            || queryParams.requestType
            || queryParams.requestStatus
            || queryParams.search
            || queryParams.dateFrom
            || queryParams.dateTo
            || queryParams.partner
            || queryParams.playerQty
            || queryParams.allocTime
            || queryParams.userName
        ) {
            setShowFilter(true);
        } else {
            setShowFilter(false);
        }

        setSearch(queryParams.search);
        setRequestStatus(queryParams.requestStatus);
        setDateFrom(queryParams.dateFrom);
        setDateTo(queryParams.dateTo);
        setPerPage(queryParams.perPage || 10);
        setSortBy(queryParams.orderBy || 'updated_at');

        if (queryParams.requestType) {
            let type = request_types.find(type => {
                return Number(queryParams.requestType) === type.id;
            });

            setRequestType(type);
        } else {
            setRequestType(null);
        }


        let endpoint = '/api/brand-request/user/list';

        if (adminMode) {
            endpoint = '/api/brand-admin';
        }

        setLoaded(false);

        axios
            .get(endpoint, {
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                if (res.data.data.length === 0) {
                    unsetQueryParams('page');
                }
                setLoaded(true)
                setMeta(res.data.meta);
                setRequests(res.data.data);
            });
    };

    const path = props.match.path;

    React.useEffect(loadData, [props.location.search]);

    React.useEffect(() => {
        axios
            .get("/api/brand-request-type", {
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                setRequestTypes(res.data.requestTypes.data);

                if (queryParams.requestType) {
                    let type = res.data.requestTypes.data.find(type => {
                        return Number(queryParams.requestType) === type.id;
                    });

                    setRequestType(type);
                }
            });
    }, []);

    const goToPage = (page) => {
        setQueryParams({ page })
    }

    const setSortByParam = (value) => {
        setQueryParams({ sortBy: value })
    }

    const setType = (value) => {

        if (queryParams.type !== value) {
            setRequests([]);
            queryParams = {};
            selectTalentRequestType(null);
            setQueryParams({ type: value });
        }
    }

    const setRequestTypeParam = (value) => {
        setQueryParams({ requestType: value ? value.id : null })
    }

    const setPerPageParam = (value) => {
        setQueryParams({ perPage: value })
    }

    const setUserNameParam = (value) => {
        setQueryParams({ userName: value })
    }

    const setQueryParams = (params) => {
        Object.keys(params).forEach(key => {
            if (!params[key]) {
                delete params[key];
                delete queryParams[key];
            }
        });

        props.history.push({ pathname: path, search: qs.stringify({ ...queryParams, ...params }) });
    }

    const setRequestStatusParam = (value) => {
        setQueryParams({ requestStatus: value })
    }

    const setSearchParam = (value) => {
        setQueryParams({ search: value })
    }

    const setTalentRequestTypeFilter = (value) => {
        if (value === 'player-commercial') {
            setQueryParams({ accessType: 'players', talentRequestType: 'commercial appearance' });
        } else {
            setQueryParams({ accessType: 'staff', talentRequestType: value });
        }
        selectTalentRequestType(value);
    }

    const handleDateRange = (start, end) => {
        setQueryParams({
            dateFrom: momentNative(start.format()).format('YYYY-MM-DD'),
            dateTo: momentNative(end.format()).format('YYYY-MM-DD')
        });

        setDateFrom(start.format());
        setDateTo(end.format());
    };

    const sorByValues = {
        updated_at: 'Date',
        name: 'Name',
        request_type_id: 'Request Type',
        status: 'Status',
    };

    const perPageValues = [10, 25, 50, 100];

    let statusValues = {
        pending: {
            class: 'grey',
            name: 'Pending',
        },
        cancelled: {
            class: 'dark',
            name: 'Cancelled',
        },
        approved: {
            class: 'green',
            name: 'Approved',
        },
        change: {
            class: 'orange',
            name: 'Reallocation',
        },
        rejected: {
            class: 'red',
            name: 'Rejected',
        }
    };

    const unsetQueryParams = (...parameters) => {
        if (Array.isArray(parameters)) {
            parameters.forEach(param => {
                delete queryParams[param];
            })
        } else {
            delete queryParams[parameters]
        }

        props.history.push({ pathname: path, search: qs.stringify(queryParams) });
    }

    const handleCancelDateRange = (event, picker) => {
        picker.element.val('');

        unsetQueryParams('dateFrom', 'dateTo');
    };

    const handleApplyDateRange = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
    };

    const setDefault = () => {

    }

    return (
        <>
            {!loaded &&
                <div className="media-loader-container">
                    <CircularProgress color="secondary" className="loaded-content h-100" />
                </div>
            }
            <section className="request-list-wrapper">
                <div className="brand-approval-requests-list">
                    <div className="filters d-flex justify-content-end mb-4">
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                <Typography className="type-title">
                                    {!tabRequestType ? 'All Requests' : tabRequestType === 'talent' ? 'Talent Access' : 'Approvals'}
                                </Typography>
                            </Grid>
                            <Grid item className="d-flex">
                                <Grid container direction="row" spacing={2}>
                                    <Grid item>
                                        <Grid container direction="row" spacing={1}>
                                            <Grid item>
                                                <Typography className="filter-label">SORT:</Typography>
                                            </Grid>
                                            <Grid item>
                                                <DropdownButton title={sorByValues[queryParams.sortBy] || sorByValues[sortBy]}>
                                                    {Object.entries(sorByValues).map((value, key) => {
                                                        return (
                                                            <Dropdown.Item key={key} onClick={() => setSortByParam(value[0])}>
                                                                {value[1]}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </DropdownButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" spacing={1}>
                                            <Grid item>
                                                <Typography className="filter-label">SHOW:</Typography>
                                            </Grid>
                                            <Grid item>
                                                <DropdownButton title={perPage}>
                                                    {perPageValues.map((value) => {
                                                        return (
                                                            <Dropdown.Item key={value}
                                                                onClick={() => setPerPageParam(value)}>{value}</Dropdown.Item>
                                                        );
                                                    })}
                                                </DropdownButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {!showFilter && (
                                            <div className="filter-icons-wrapper" onClick={() => setShowFilter(true)}>
                                                <FiltersIconBlack /></div>
                                        )}
                                        {showFilter && (
                                            <div className="filter-icons-wrapper active" onClick={() => setShowFilter(false)}>
                                                <FiltersIconWhite /></div>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <Grid container direction="column">
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Permitted one="admin_review_approvals_request">
                                        <ApprovalRequestSidebar
                                            meta={meta}
                                            requestStatus={requestStatus}
                                            setRequestStatusParam={setRequestStatusParam}
                                            tabRequestType={tabRequestType}
                                            statusValues={statusValues}
                                            setType={setType}
                                        />
                                    </Permitted>
                                </Grid>
                            </Grid>
                        </Grid >

                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                            {tabRequestType === 'approvals' &&
                                <Permitted one="admin_review_approvals_request">
                                    <ApprovalsRequestList
                                        {
                                        ...{
                                            classes,
                                            adminMode,
                                            setSearchParam,
                                            queryParams,
                                            search,
                                            setRequestTypeParam,
                                            request_types,
                                            showFilter,
                                            requestType,
                                            handleCancelDateRange,
                                            handleApplyDateRange,
                                            handleDateRange,
                                            dateFrom,
                                            dateTo,
                                            requestStatus,
                                            statusValues,
                                            setRequestStatusParam,
                                            requests,
                                            setQueryParams,
                                        }
                                        }
                                    />
                                </Permitted>

                            }
                            {tabRequestType === 'talent' &&
                                <Permitted one="admin_review_talent_request">
                                    <TalentRequestList
                                        {
                                        ...{
                                            classes,
                                            adminMode,
                                            setSearchParam,
                                            setQueryParams,
                                            queryParams,
                                            setRequestTypeParam,
                                            request_types,
                                            showFilter,
                                            requestType,
                                            handleCancelDateRange,
                                            handleApplyDateRange,
                                            handleDateRange,
                                            dateFrom,
                                            dateTo,
                                            requestStatus,
                                            statusValues,
                                            talentRequestType,
                                            setRequestStatusParam,
                                            requests,
                                            setUserNameParam,
                                        }
                                        }
                                    />
                                </Permitted>
                            }
                            {tabRequestType === 'approvals'
                                ?
                                <ul className="list-inline pt-2">
                                    <li className="list-inline-item points grey">
                                        <FiberManualRecord />
                                        Pending
                                    </li>
                                    <li className="list-inline-item points green">
                                        <FiberManualRecord />
                                        Approved
                                    </li>
                                    <li className="list-inline-item points orange">
                                        <FiberManualRecord />
                                        Awaiting Changes
                                    </li>
                                    <li className="list-inline-item points red">
                                        <FiberManualRecord />
                                        Rejected
                                    </li>

                                    <li className="list-inline-item points dark">
                                        <FiberManualRecord />
                                        Cancelled
                                    </li>
                                </ul>
                                :
                                <br />
                            }

                            {
                                meta.per_page < meta.total && loaded && (
                                    <Grid container direction="column" alignContent="flex-end">
                                        <Pagination
                                            shape="rounded"
                                            count={meta.last_page}
                                            page={meta.current_page}
                                            onChange={(e, v) => goToPage(v)}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state => ({
    user: state.user.user
}))
export default connect(mapStateToProps)(withRouter(RequestsList));
