import { authHeader } from "~/helpers/authHeader";

export const sendResetPassword = (usersEmail) => {

    return fetch('/api/users/send-reset', {
        method: 'POST',
        credentials: 'include',
        headers: authHeader(),
        body: JSON.stringify({
            email: usersEmail,
        })
    })

}
