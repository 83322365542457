import React, {useCallback, useEffect, useState} from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {debounce} from "lodash";
import axios from "axios";

const PartnersAutocomplete = ({setFieldValue, initialValues}) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;
    const [partnerSearch, setPartnerSearch] = useState('');
    const [partners, setPartners] = useState([]);

    useEffect(() => {

    }, []);

    const searchUsersHandler = (search) => {
        getPartners(search)
    }

    const getPartners = (search) => {
        let searchParam = search ? `?name=${search}` : '';

        axios.get('/api/partners' + searchParam).then(res => {
            setPartners(res.data.data);
        });
    }

    const debouncedOnInput = debounce(searchUsersHandler, 300);
    const inputHandler = useCallback((search) => {
        debouncedOnInput(search);
    }, []);

    useEffect(() => {
        inputHandler(partnerSearch);
    }, [partnerSearch]);

    return (
        <>{partners &&

        <Autocomplete
            multiple
            debug={true}
            options={partners}
            value={initialValues}
            onChange={(event, value) => {
                setFieldValue(value);
            }}
            onInputChange={(event) => setPartnerSearch(event.target ? event.target.value : '')}
            fullWidth
            disableCloseOnSelect={true}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) => option.name}
            renderOption={(option, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                        value={option.id}
                        disabled={true}
                    />
                    {option.name}
                </React.Fragment>
            )}
            getOptionSelected={((option, value) => option.id === value.id)}
            renderInput={(params) => (
                <TextField
                    InputProps={{
                        style: {
                            borderRadius: 0,
                            borderColor: '#51b7ab'
                        }
                    }}
                    {...params}
                    color="primary"
                    margin="dense"
                    variant="outlined"
                />
            )}
        />
        }
        </>
    );
};

export default PartnersAutocomplete;
