import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';

import Login from '../Login/Login';//
import Media from '../Media';
import Reset from '../Login/Reset';
import NewPass from '../Login/NewPass';
import ChangePassword from '../Login/ChangePassword';
import Dashboard from '../Dashboard/Dashboard';
import AppLoader from '../AppLoader';
import GAListener from '../GAListener';
import ProtectedRoute from '../ProtectedRoute';
import store from '../Store';

import BrandApprovalRequest from '~/BrandApprovalRequest/index';
import TalentAccessRequest from '~/Admin/RequestManagement/TalentAccessRequest/index';
import Header from '~/Components/Header/Header';
import MyRequests from '~/MyRequests';
import Approvals from '~/Approvals';

import Admin from '~/Admin/Admin';
import UserManagement from '~/Admin/UserManagements/UserManagements';
import NotificationProvider from '~/Notification/NotificationProvider';
import {ThemeProvider, createTheme} from '@material-ui/core';
import {ConfirmProvider} from 'material-ui-confirm';

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import RequestManagement from '~/Admin/RequestManagement/RequestManagement';
import PageNotFound from '~/PageNotFound/PageNotFound';
import PartnerManagement from '~/Admin/PartnerManagement/PartnerManagement';
import InventoryManagement from '~/Admin/InventoryManagement/InventoryManagement';
import GroupAllocation from '~/Admin/RequestManagement/GroupAllocation/GroupAllocation';
import ContentManagement from '~/Admin/PartnerManagement/ContentManagement/ContentManagement';
import WelcomePage from '../WelcomePage/WelcomePage';
import SignUp from '../Login/SignUp';
import Partners from '../Partners/Partners';
import PartnersPage from '../PartnersPage/PartnersPage';
import Assets from '~/Assets/Assets';
import MatchTicketRequest from '~/AssetsRequests/MatchTicket/MatchTicketRequest';
import MerchandiseRequest from '~/AssetsRequests/Merchandise/MerchandiseRequest';
import NoticeBoard from '../Dashboard/NoticeBoard';
import ApprovalsReview from '../Approvals/components/ApprovalsReview';
import TicketRequestReview from '../MyRequests/TicketRequestReview';
import MerchRequestReview from '../MyRequests/MerchRequestReview';

const THEME = createTheme({
  palette: {
    primary: {
      main: '#01B7AB',
    },
    secondary: {
      main: '#FA5519',
    },
    error: {
      main: '#fa2113',
    },
  },
  typography: {
    fontFamily: ['GT-Eesti-Pro-Display-Light'].join(','),
    h1: {
      fontFamily: 'GT-Eesti-Pro-Display-UBold',
      fontSize: 56,
      color: '#083D49',
    },
    h2: {
      fontFamily: 'GT-Eesti-Pro-Display-UBold',
      fontWeight: 'bold',
      fontSize: 30,
    },
    h3: {},
    h4: {
      fontFamily: 'GT-Eesti-Pro-Display-Bold',
      fontSize: 18,
      color: '#FA2113',
    },
    h5: {
      fontFamily: 'GT-Eesti-Pro-Display-UBold',
      fontSize: 12,
    },
    h6: {
      fontFamily: 'GT-Eesti-Pro-Display-Light',
      fontSize: 16,
    },
    subtitle1: {
      fontFamily: 'GT-Eesti-Pro-Display-Bold',
      fontSize: '3.5rem',
      color: '#083D49',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: 'GT-Eesti-Pro-Display-UBold',
      fontSize: '1.875rem',
    },
    body1: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '21px',
    },
    body2: {
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  primaryColor: '#af001e',
  secondaryColor: '#FA5519',
  lightGrey: '#999bb5',
  dark: '#3e3f60',
  brickColor: '#de5644',
  buttonGreen: '#28FF7A',
  buttonDark: '#000000',
  demiBold: 'AvenirNext-DemiBold',
  lightFont: 'GT-Eesti-Pro-Display-Light',
  mediumFont: 'GT-Eesti-Pro-Display-Medium',
  boldFont: 'GT-Eesti-Pro-Display-Bold',
  ultraBoldFont: 'GT-Eesti-Pro-Display-UBold',
  overrides: {
    MuiChip: {
      root: {
        borderRadius: 0,
        marginRight: 2,
      },
    },
    MuiButton: {
      contained: {
        width: 142,
        height: 32,
      },
    },
  },
});

class App extends Component {

  render() {
    return (
      <ThemeProvider theme={THEME}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider store={store}>
            <NotificationProvider>
              <ConfirmProvider>
                <AppLoader>
                  <BrowserRouter>
                    <Header>
                      <GAListener>
                        <Switch>
                          <Route
                            exact
                            path='/'
                            component={Login} />

                          <Route
                            exact
                            path='/sign-up'
                            component={SignUp} />

                          <ProtectedRoute
                            exact
                            path='/welcome'
                            component={WelcomePage} />

                          <Route
                            exact
                            path='/reset'
                            component={Reset} />

                          <Route
                            exact
                            path='/new-password/:token'
                            component={NewPass} />

                          <ProtectedRoute
                            path='/change-password'
                            component={ChangePassword} />

                          <ProtectedRoute
                            exact
                            path='/dashboard'
                            component={Dashboard} />

                          <ProtectedRoute
                            path="/dashboard/view-notices-deadlines"
                            component={NoticeBoard} />

                          <ProtectedRoute
                            exact
                            some={[
                              'series_information',
                              'edit_ticket_content',
                              'upload_delete_ticket_files',
                              'edit_activation_content',
                              'upload_delete_activation_files',
                              'edit_rights_protection_content',
                              'upload_delete_rights_protection_files',
                              'edit_documents_content',
                              'upload_delete_document_files',
                              'key_contacts',
                              'view_ticket_hospitality',
                              'view_activation',
                              'view_right_protection',
                              'view_documents',
                              'view_key_deadlines',
                            ]}
                            path='/partners'
                            component={Partners} />

                          <ProtectedRoute
                            some={[
                              'series_information',
                              'edit_ticket_content',
                              'upload_delete_ticket_files',
                              'edit_activation_content',
                              'upload_delete_activation_files',
                              'edit_rights_protection_content',
                              'upload_delete_rights_protection_files',
                              'edit_documents_content',
                              'upload_delete_document_files',
                              'key_contacts',
                              'view_ticket_hospitality',
                              'view_activation',
                              'view_right_protection',
                              'view_documents',
                              'view_key_deadlines',
                            ]}
                            path='/partners/:partnerSlug'
                            component={PartnersPage} />

                          <ProtectedRoute
                            some={[
                              'view_brand_guidelines',
                              'view_logo_assets',
                              'edit_brand_guidelines_content',
                              'upload_delete_brand_guidelines_files',
                              'upload_logo_assets_content',
                              'edit_existing_logo_assets',
                              'delete_existing_logo_assets',
                              'create_edit_delete_logo_asset_folder',
                            ]}
                            path='/best-practice'
                            libraryType="best-practice"
                            component={Media} />
                          <ProtectedRoute
                            some={[
                              'view_brand_guidelines',
                              'view_logo_assets',
                              'edit_brand_guidelines_content',
                              'upload_delete_brand_guidelines_files',
                              'upload_logo_assets_content',
                              'edit_existing_logo_assets',
                              'delete_existing_logo_assets',
                              'create_edit_delete_logo_asset_folder',
                            ]}
                            path='/brand'
                            libraryType="brand"
                            component={Media} />
                          <ProtectedRoute
                            some={[
                              'view_brand_guidelines',
                              'view_logo_assets',
                              'edit_brand_guidelines_content',
                              'upload_delete_brand_guidelines_files',
                              'upload_logo_assets_content',
                              'edit_existing_logo_assets',
                              'delete_existing_logo_assets',
                              'create_edit_delete_logo_asset_folder',
                            ]}
                            path='/content'
                            libraryType="content"
                            component={Media} />

                          <ProtectedRoute
                            some={[
                              'submit_approval_request',
                              'review_approvals_request',
                              'view_approvals',
                            ]}
                            exact
                            path="/approvals"
                            component={Approvals} />

                          <ProtectedRoute
                            some={[
                              'submit_approval_request',
                              'review_approvals_request',
                              'view_approvals',
                            ]}
                            exact
                            path="/approvals/:id"
                            component={ApprovalsReview} />

                          <ProtectedRoute
                            path='/assets'
                            component={Assets} />

                          <ProtectedRoute
                            path='/match-ticket-request'
                            component={MatchTicketRequest} />

                          <ProtectedRoute
                            path='/merchandise-request'
                            component={MerchandiseRequest} />

                          <ProtectedRoute
                            some={[
                              'review_talent_request',
                              'review_approvals_request',
                            ]}
                            exact
                            path="/my-requests"
                            component={MyRequests} />

                          <ProtectedRoute
                            some={[
                              'submit_talent_request',
                              'submit_approval_request',
                            ]}
                            exact
                            path="/my-requests/merch/:id"
                            component={MerchRequestReview} />

                          <ProtectedRoute
                            some={[
                              'submit_talent_request',
                              'submit_approval_request',
                            ]}
                            exact
                            path="/my-requests/ticket/:id"
                            component={TicketRequestReview} />

                          <ProtectedRoute
                            some={[
                              'admin_view_ticket_hospitality',
                              'admin_view_activation',
                              'admin_view_right_protection',
                              'admin_view_documents',
                              'admin_view_brand_guidelines',
                              'admin_view_logo_assets',
                              'admin_view_archive',
                              'admin_view_right_documentations',
                              'admin_review_talent_request',
                              'admin_view_talent_access',
                              'admin_review_approvals_request',
                              'view_user_list',
                              'view_role_list',
                              'view_partner_list',
                            ]}
                            path='/admin'
                            component={Admin} />

                          <ProtectedRoute
                            some={[
                              'view_user_list',
                              'create_new_user',
                              'edit_existing_user',
                              'delete_existing_user',
                              'create_new_admin',
                              'edit_existing_admin',
                              'delete_existing_admin',
                              'view_role_list',
                              'create_new_role',
                              'edit_existing_role',
                              'delete_existing_role',
                              'create_new_admin_role',
                              'edit_existing_admin_role',
                              'delete_existing_admin_role',
                              'mass_delete_users',
                            ]}
                            path={`/admin/user-management`}
                            component={UserManagement} />

                          <ProtectedRoute
                            one="view_partner_list"
                            path={`/admin/partner-management`}
                            component={PartnerManagement} />

                          <ProtectedRoute
                            exact
                            some={[
                              // admin content management Lions Tour 21
                              'admin_view_ticket_hospitality',

                              // admin content management Brand
                              'admin_view_brand_guidelines',

                              // admin content management Media Library
                              'admin_view_archive',

                              // admin content management Rights and Documentation
                              'admin_view_right_documentations',

                              // admin content management Requests
                              'admin_view_talent_access',
                              'edit_approvals',
                              'upload_delete_approvals_files',


                              'admin_view_activation',
                              'admin_view_right_protection',
                              'admin_view_documents',
                              'admin_view_key_deadlines',
                              'admin_view_brand_guidelines',
                              'admin_view_imagery_footage',
                              'admin_review_talent_request',
                              'admin_view_talent_access',
                              'admin_review_approvals_request',

                              'edit_ticket_content',
                              'upload_delete_ticket_files',
                              'edit_activation_content',
                              'upload_delete_activation_files',
                              'edit_rights_protection_content',
                              'upload_delete_rights_protection_files',
                              'edit_rights_documentations',
                              'upload_delete_rights_documentations_files',
                              'edit_brand_guidelines_content',
                              'upload_delete_brand_guidelines_files',
                              'edit_archive_content',
                              'edit_archive_links',
                              'edit_talent_access',
                              'upload_delete_talent_access_files',
                              'edit_approvals',
                              'upload_delete_approvals_files',
                            ]}
                            path={`/admin/content-management/:partnerId`}
                            component={ContentManagement} />

                          <ProtectedRoute
                            some={[
                              'submit_talent_request',
                              'edit_talent_access',
                              'upload_delete_talent_access_files',
                              'view_talent_access_inventory',
                              'create_talent_access_assets',
                              'edit_existing_talent_access',
                              'delete_talent_access_existing',
                            ]}
                            path={`/admin/inventory-management/:form?`}
                            component={InventoryManagement} />

                          <ProtectedRoute
                            some={[
                              'admin_review_talent_request',
                              'admin_review_approvals_request',
                            ]}
                            exact
                            path={`/admin/request-management`}
                            component={RequestManagement} />

                          <ProtectedRoute
                            some={[
                              'admin_review_talent_request',
                              'admin_review_approvals_request',
                            ]}
                            adminMode={true}
                            path={`/admin/request-management/:id`}
                            component={BrandApprovalRequest} />

                          <ProtectedRoute
                            one="admin_review_talent_request"
                            adminMode={true}
                            path={`/admin/talent-request-management/:id`}
                            component={TalentAccessRequest} />

                          <ProtectedRoute
                            one="admin_review_talent_request"
                            adminMode={true}
                            path={`/admin/talent-request-group-allocation`}
                            component={GroupAllocation} />

                          <ProtectedRoute component={PageNotFound} />
                        </Switch>
                      </GAListener>
                    </Header>
                  </BrowserRouter>
                </AppLoader>
              </ConfirmProvider>
            </NotificationProvider>
          </Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default App;
