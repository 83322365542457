import React, { useState } from 'react';
import { Divider, Grid, Tabs, Tab, makeStyles, Typography, Card, Button } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { Field } from 'formik';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { resetOverridesData } from '../cmsInitialValues';
import { EditorState } from 'draft-js';
import Dropzone from "react-dropzone";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Permitted from "~/Permissions/Permitted";
import PermissionChecker from "~/Permissions/PermissionChecker";

const TabPanel = (props) => {
    const {
        children,
        value,
        index,
        ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}>
            {value === index && (
                children
            )}
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    tabs: {
        display: 'block',
        left: 0,
        backgroundColor: theme.primaryColor,
    },
    tab: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
    },
}));

const LionsTour21 = (props) => {

    const {
        setFieldValue,
        setRightProtectionFiles,
        rightProtectionFiles,
        documentsFiles,
        setDocumentsFiles,
        previewUpload,
        rightProtectionEditorState,
        setRightProtectionEditorState,
        documentsEditorState,
        setDocumentsEditorState,
        setRenameImage,
        handleOpenImageDialog,
        subTab,
        setRenameSection,
        setRenameRenameFileIndex,
        user
    } = props;

    const classes = useStyles();
    const [value, setValue] = useState(Number(subTab));
    const checker = new PermissionChecker(user);

    // RESET SINGLE FIELD STATE TO INITIAL
    const resetField = (field) => {
        switch (field) {
            case "right_protection":
                setRightProtectionEditorState(EditorState.createWithContent(convertFromHTML(resetOverridesData.lions_tour.right_protection)));
                setFieldValue('lions_tour.right_protection', resetOverridesData.lions_tour.right_protection);
                break;
            case "documents":
                setDocumentsEditorState(EditorState.createWithContent(convertFromHTML(resetOverridesData.lions_tour.documents)));
                setFieldValue('lions_tour.documents', resetOverridesData.lions_tour.documents);
                break;
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const menuItems = [
        checker.one("admin_view_ticket_hospitality") && {
            name: 'Tickets & Hospitality',
            component: <Grid item>
                <Grid container direction="row" alignItems="stretch">
                    <Permitted one="edit_ticket_content">
                        <Grid item xs={8} sm={8} md={8} xl={8} lg={8}>
                        </Grid>
                    </Permitted>
                    <Permitted one="upload_delete_ticket_files">
                        <Grid item xs={4} sm={4} md={4} xl={4} lg={4}>
                        </Grid>
                    </Permitted>
                </Grid>
            </Grid>
        },
        checker.one("admin_view_activation") && {
            name: 'Activation',
            component: <Grid item>
                <Grid container direction="row" alignItems="stretch">
                    <Permitted one="edit_activation_content">
                        <Grid item xs={8} sm={8} md={8} xl={8} lg={8}>
                        </Grid>
                    </Permitted>
                    <Permitted one="upload_delete_activation_files">
                        <Grid item xs={4} sm={4} md={4} xl={4} lg={4}>
                        </Grid>
                    </Permitted>
                </Grid>
            </Grid>
        },
        checker.one("admin_view_right_protection") && {
            name: 'Rights Protection',
            component: <Grid container direction="column" style={{ padding: 30 }}>
                <Grid item>
                    <Grid container direction="row" spacing={6}>
                        <Permitted one="edit_rights_protection_content">
                            <Grid item xs sm md xl lg>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Typography style={{
                                            color: '#3e3f60',
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }}>Rights & Protection Informations</Typography>
                                        <Grid container direction="row" alignItems="baseline"
                                            justifyContent="space-between">
                                            <Grid item>
                                                <Typography
                                                    style={{ fontSize: 10, color: '#999bb5' }}>Edit to
                                                    the below text will only be applied for the
                                                        selected partner tier.</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Field name="right_protection">
                                            {({ field }) => (
                                                <Editor
                                                    {...field}
                                                    editorState={rightProtectionEditorState}
                                                    name="right_protection"
                                                    editorStyle={{
                                                        border: '1px solid #c6c9db',
                                                        padding: 10,
                                                        minHeight: 300,
                                                        maxHeight: 300,
                                                        overflowY: 'auto'
                                                    }}
                                                    placeholder="Please add content here"
                                                    onEditorStateChange={(e) => {
                                                        setFieldValue('lions_tour.right_protection', convertToHTML(e.getCurrentContent()));
                                                        setRightProtectionEditorState(e);
                                                    }}
                                                    toolbar={{
                                                        options: ['inline', 'list'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'underline'],
                                                        },
                                                        list: {
                                                            options: ['unordered'],
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Permitted>
                        <Permitted one="upload_delete_rights_protection_files">
                            <Grid item xs={5} sm={5} md={5} xl={5} lg={5}>
                                <Grid container direction="column" justifyContent="space-between"
                                    style={{ minHeight: '100%' }}>
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center"
                                            justifyContent="space-between">
                                            <Grid item>
                                                <Typography style={{
                                                    color: '#3e3f60',
                                                    fontSize: 14,
                                                    fontWeight: 'bold'
                                                }}>Terms & Conditions</Typography>
                                                { rightProtectionFiles.length === 0 && <Typography style={{ color: '#999bb5', fontSize: 10 }}>No documents yet.</Typography> }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" spacing={4}>
                                            {
                                                rightProtectionFiles && rightProtectionFiles.map((file, key) => (
                                                    <Grid item key={key}>
                                                        <Grid container direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between">
                                                            <Grid item>
                                                                <Grid container direction="row"
                                                                    alignItems="baseline" spacing={1}>
                                                                    <Grid item>
                                                                        {
                                                                            (file.type === 'image/jpeg' || file.type === 'image/png' || file.ext === 'jpg' || file.ext === 'png') &&
                                                                            <img
                                                                                src="/images/icons/SVG/File Type/Image.svg"
                                                                                style={{
                                                                                    width: 21,
                                                                                    height: 21
                                                                                }} alt="image" />
                                                                        }
                                                                        {
                                                                            (file.type === 'application/pdf' || file.ext === 'pdf') &&
                                                                            <img
                                                                                src="/images/icons/SVG/File Type/PDF.svg"
                                                                                style={{
                                                                                    width: 21,
                                                                                    height: 21
                                                                                }} alt="pdf" />
                                                                        }
                                                                        {
                                                                            (file.type === 'application/msword' || file.type === 'application/vnd.ms-excel' || file.type === 'application/xslt+xml' || file.ext === 'xsl' || file.ext === 'doc') &&
                                                                            <img
                                                                                src="/images/icons/SVG/File Type/File.svg"
                                                                                style={{
                                                                                    width: 21,
                                                                                    height: 21
                                                                                }} alt="file" />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography style={{
                                                                            color: '#3e3f60',
                                                                            fontSize: 16
                                                                        }}>{file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography style={{
                                                                            color: '#d8dae6',
                                                                            fontSize: 10,
                                                                            fontWeight: 'bold'
                                                                        }}>
                                                                            {
                                                                                typeof file.size === 'number' ? (file.size > 0 && (file.size * 0.000001).toFixed(2)) : (Number(file.size) > 0 && (Number(file.size) / 1000).toFixed(2))
                                                                            }
                                                                            MB</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row"
                                                                    spacing={2}>
                                                                    <Grid item>
                                                                        <img
                                                                            src="/images/icons/SVG/View.svg"
                                                                            style={{
                                                                                width: 21,
                                                                                height: 21,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            alt="view"
                                                                            onClick={() => previewUpload(file)} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <img
                                                                            src="/images/icons/SVG/Edit.svg"
                                                                            style={{
                                                                                width: 21,
                                                                                height: 21,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            alt="edit"
                                                                            onClick={() => {
                                                                                setRenameImage(file.name);
                                                                                setRenameSection('right_protection_files');
                                                                                setRenameRenameFileIndex(key);
                                                                                handleOpenImageDialog();
                                                                            }} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <img
                                                                            src="/images/icons/SVG/Close.svg"
                                                                            style={{
                                                                                width: 21,
                                                                                height: 21,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            alt="image"
                                                                            onClick={() => {
                                                                                let newFiles = rightProtectionFiles.filter((item, iKey) => iKey !== key);
                                                                                setRightProtectionFiles(newFiles);
                                                                                setFieldValue('lions_tour.right_protection_files', newFiles);
                                                                            }} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 'bold',
                                                        textTransform: 'uppercase',
                                                        color: '#af001e'
                                                    }}>
                                                    Upload files
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: 10,
                                                        color: '#999bb5'
                                                    }}>
                                                    You can upload new files to be uploaded below by either clicking on the button below, or dropping the file on the button
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Dropzone
                                                    accept=".pdf,.doc,.jpg,.png,.xsl"
                                                    minSize={0}
                                                    onDrop={x => {
                                                        let newFiles = [];
                                                        if (rightProtectionFiles && rightProtectionFiles.length > 0) {
                                                            newFiles = [...rightProtectionFiles, ...x];
                                                        } else {
                                                            newFiles = [...x];
                                                        }
                                                        setRightProtectionFiles(newFiles);
                                                        setFieldValue('lions_tour.right_protection_files', newFiles);
                                                    }}>
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                        isDragActive,
                                                        isDragReject
                                                    }) => {
                                                        return (
                                                            <div style={{
                                                                width: '100%',
                                                                background: '#f3f4f8',
                                                                border: '3px dashed #d8dae6',
                                                                color: '#3e3f60',
                                                                padding: 30,
                                                                textAlign: 'center',
                                                                position: 'relative'
                                                            }}
                                                                {...getRootProps()}>
                                                                <div className="upload-icon">
                                                                    <img
                                                                        src="/images/icons/SVG/Upload-red.svg"
                                                                        alt="upload" />
                                                                </div>
                                                                <h4 className="upload-title">
                                                                    Drag & Drop
                                                                    </h4>
                                                                <div className="form-group">
                                                                    <div className="dropZone">
                                                                        <input {...getInputProps()} />
                                                                        {!isDragActive &&
                                                                            <span>Or you can <u>browse</u> your files</span>}
                                                                        {isDragActive && isDragReject && "Drop it like it's hot!"}
                                                                        {isDragActive && !isDragReject && "File type not accepted, sorry!"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Dropzone>
                                                <Typography
                                                    style={{ fontSize: 10, color: '#999bb5' }}>Accepted
                                                        Formats: pdf, doc, jpg, png, xsl</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Permitted>
                    </Grid>
                </Grid>
                <Permitted one="edit_rights_protection_content">
                    <Grid item>
                        <Button
                            style={{
                                color: '#af001e',
                                fontSize: 16,
                                fontWeight: 'bold',
                                textTransform: 'uppercase'
                            }}
                            onClick={() => resetField('right_protection')}>
                            Reset Overrides
                        </Button>
                    </Grid>
                </Permitted>
            </Grid>
        },
        checker.some([
            "admin_view_documents",
            "edit_documents_content",
            "upload_delete_document_files"
        ]) && {
            name: 'Documents',
            component: <Grid container direction="column" style={{ padding: 30 }}>
                <Grid item>
                    <Grid container direction="row" spacing={6}>
                        <Permitted one="edit_documents_content">
                            <Grid item xs sm md xl lg>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Typography style={{
                                            color: '#3e3f60',
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }}>Documents</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Field name="documents">
                                            {({ field }) => (
                                                <Editor
                                                    {...field}
                                                    name="documents"
                                                    editorState={documentsEditorState}
                                                    editorStyle={{
                                                        border: '1px solid #c6c9db',
                                                        padding: 10,
                                                        minHeight: 300,
                                                        maxHeight: 300,
                                                        overflowY: 'auto'
                                                    }}
                                                    placeholder="Please add content here"
                                                    onEditorStateChange={(e) => {
                                                        setFieldValue('lions_tour.documents', convertToHTML(e.getCurrentContent()));
                                                        setDocumentsEditorState(e);
                                                    }}
                                                    toolbar={{
                                                        options: ['inline', 'list'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'underline'],
                                                        },
                                                        list: {
                                                            options: ['unordered'],
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Permitted>
                        <Permitted one="upload_delete_document_files">
                            <Grid item xs={5} sm={5} md={5} xl={5} lg={5}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-between"
                                    style={{ minHeight: '100%' }}>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between">
                                            <Grid item>
                                                <Typography
                                                    style={{
                                                        color: '#3e3f60',
                                                        fontSize: 14,
                                                        fontWeight: 'bold'
                                                    }}>
                                                    Documents
                                                </Typography>
                                                { documentsFiles.length === 0 && <Typography style={{ color: '#999bb5', fontSize: 10 }}>No documents yet.</Typography> }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" spacing={4}>
                                            {documentsFiles && documentsFiles.map((file, key) => (
                                                <Grid item key={key}>
                                                    <Grid container direction="row"
                                                        alignItems="center"
                                                        justifyContent="space-between">
                                                        <Grid item>
                                                            <Grid container direction="row"
                                                                alignItems="baseline" spacing={1}>
                                                                <Grid item>
                                                                    {
                                                                        (file.type === 'image/jpeg' || file.type === 'image/png' || file.ext === 'jpg' || file.ext === 'png') &&
                                                                        <img
                                                                            src="/images/icons/SVG/File Type/Image.svg"
                                                                            style={{
                                                                                width: 21,
                                                                                height: 21
                                                                            }} alt="image" />
                                                                    }
                                                                    {
                                                                        (file.type === 'application/pdf' || file.ext === 'pdf') &&
                                                                        <img
                                                                            src="/images/icons/SVG/File Type/PDF.svg"
                                                                            style={{
                                                                                width: 21,
                                                                                height: 21
                                                                            }} alt="pdf" />
                                                                    }
                                                                    {
                                                                        (file.type === 'application/msword' || file.type === 'application/vnd.ms-excel' || file.type === 'application/xslt+xml' || file.ext === 'xsl' || file.ext === 'doc') &&
                                                                        <img
                                                                            src="/images/icons/SVG/File Type/File.svg"
                                                                            style={{
                                                                                width: 21,
                                                                                height: 21
                                                                            }} alt="file" />
                                                                    }
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{
                                                                        color: '#3e3f60',
                                                                        fontSize: 16
                                                                    }}>{file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name}</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{
                                                                        color: '#d8dae6',
                                                                        fontSize: 10,
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                        {
                                                                            typeof file.size === 'number' ? (file.size > 0 && (file.size * 0.000001).toFixed(2)) : (Number(file.size) > 0 && (Number(file.size) / 1000).toFixed(2))
                                                                        }
                                                                            MB</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row"
                                                                spacing={2}>
                                                                <Grid item>
                                                                    <img
                                                                        src="/images/icons/SVG/View.svg"
                                                                        style={{
                                                                            width: 21,
                                                                            height: 21,
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        alt="view"
                                                                        onClick={() => previewUpload(file)} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <img
                                                                        src="/images/icons/SVG/Edit.svg"
                                                                        style={{
                                                                            width: 21,
                                                                            height: 21,
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        alt="edit"
                                                                        onClick={() => {
                                                                            setRenameImage(file.name);
                                                                            setRenameSection('documents_files');
                                                                            setRenameRenameFileIndex(key);
                                                                            handleOpenImageDialog();
                                                                        }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <img
                                                                        src="/images/icons/SVG/Close.svg"
                                                                        style={{
                                                                            width: 21,
                                                                            height: 21,
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        alt="image"
                                                                        onClick={() => {
                                                                            let newFiles = documentsFiles.filter((item, iKey) => iKey !== key);
                                                                            setDocumentsFiles(newFiles);
                                                                            setFieldValue('lions_tour.documents_files', newFiles);
                                                                        }} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography style={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                    color: '#af001e'
                                                }}>Upload files</Typography>
                                                <Typography
                                                    style={{ fontSize: 10, color: '#999bb5' }}>You can
                                                    upload new files to be uploaded below by either
                                                    clicking on the button below, or dropping the
                                                        file on the button</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Dropzone
                                                    accept=".pdf,.doc,.jpg,.png,.xsl"
                                                    minSize={0}
                                                    onDrop={x => {
                                                        let newFiles = [];
                                                        if (documentsFiles && documentsFiles.length > 0) {
                                                            newFiles = [...documentsFiles, ...x];
                                                        } else {
                                                            newFiles = [...x];
                                                        }
                                                        setDocumentsFiles(newFiles);
                                                        setFieldValue('lions_tour.documents_files', newFiles)
                                                    }}>
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                        isDragActive,
                                                        isDragReject
                                                    }) => {
                                                        return (
                                                            <div style={{
                                                                width: '100%',
                                                                background: '#f3f4f8',
                                                                border: '3px dashed #d8dae6',
                                                                color: '#3e3f60',
                                                                padding: 30,
                                                                textAlign: 'center',
                                                                position: 'relative'
                                                            }}
                                                                {...getRootProps()}>
                                                                <div className="upload-icon">
                                                                    <img
                                                                        src="/images/icons/SVG/Upload-red.svg"
                                                                        alt="upload" />
                                                                </div>
                                                                <h4 className="upload-title">
                                                                    Drag & Drop
                                                                    </h4>
                                                                <div className="form-group">
                                                                    <div className="dropZone">
                                                                        <input {...getInputProps()} />
                                                                        {!isDragActive &&
                                                                            <span>Or you can <u>browse</u> your files</span>}
                                                                        {isDragActive && isDragReject && "Drop it like it's hot!"}
                                                                        {isDragActive && !isDragReject && "File type not accepted, sorry!"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Dropzone>
                                                <Typography
                                                    style={{ fontSize: 10, color: '#999bb5' }}>Accepted
                                                        Formats: pdf, doc, jpg, png, xsl</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Permitted>
                    </Grid>
                </Grid>
                <Permitted one="edit_documents_content">
                    <Grid item>
                        <Button
                            style={{
                                color: '#af001e',
                                fontSize: 16,
                                fontWeight: 'bold',
                                textTransform: 'uppercase'
                            }}
                            onClick={() => resetField('documents')}>
                            Reset Overrides
                        </Button>
                    </Grid>
                </Permitted>
            </Grid>
        }
    ];


    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Card style={{ borderRadius: 0, minHeight: 500 }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                classes={{
                                    indicator: classes.tabs,
                                }}>
                                {
                                    menuItems
                                        .filter(x => x != false)
                                        .map((item, index) => (
                                            <Tab key={index} label={item.name} />
                                        ))
                                }
                            </Tabs>
                        </Grid>
                        <Divider />
                        {
                            menuItems
                                .filter(x => x != false)
                                .map((item, index) => (
                                    <TabPanel key={index} value={value} index={index}>
                                        {item.component}
                                    </TabPanel>
                                ))
                        }
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default LionsTour21;
