import React from 'react';
import { Button, Dialog, Grid, Typography, DialogContent, makeStyles, Select, MenuItem, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        width: 163,
        fontSize: 16,
        fontWeight: 600,
        color: '#3e3f60',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: 'transparent',
        borderRadius: 0
    },
    saveChangesButton: {
        width: 231,
        fontSize: 16,
        fontWeight: 600,
        color: '#ffffff',
        padding: '12px 11.5px 11px 18.5px',
        backgroundColor: '#af001e',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#af001e80',
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: '#af001e80'
        }
    },
    section: {
        paddingLeft: 43,
    },
    sectionTitle: {
        textTransform: 'capitalize',
        fontWeight: 600,
        color: '#3e3f60',
        fontSize: 40,
    },
    indicator: {
        color: '#af001e'
    },
    tourimg: {
        width: '100%'
    },
    table: {
        borderRadius: 0
    },
    gridPadding: {
        paddingTop: 60,
        paddingBottom: 60
    },
    textPadding: {
        paddingTop: 20
    },
    arrowIcon: {
        width: 29,
        height: 29
    },
    tabItem: {
        color: '#af001e',
        fontWeight: 'bold'
    },
    tabLink: {
        borderBottom: '2px solid #af001e',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    tabItemBlack: {
        color: 'black',
        '&:hover': {
            fontWeight: 'bold',
            color: '#af001e'
        }
    },
    tabLinkInactive: {
        '&:hover': {
            textDecoration: 'none'
        }
    }
}));


const DeletePartnerDialog = (props) => {

    const {
        deletePartnerDialog,
        handleDeletePartnerDialogClose,
        active_partner_data,
        handleDeletePartner,
        partners
    } = props;
    const classes = useStyles();

    const RequestSchema = Yup.object().shape({
        partner: Yup.string().required('Required'),
    });

    const initialValues = {
        partner: false
    };

    return (
        <Dialog
            open={deletePartnerDialog}
            onClose={handleDeletePartnerDialogClose}
            fullWidth
            maxWidth={'sm'}
            PaperProps={{
                style: {
                    borderRadius: 0,
                    padding: 10
                }
            }}>
            <div style={{ padding: 30 }}>
                <Formik
                    validationSchema={RequestSchema}
                    onSubmit={(values) => {
                        handleDeletePartner(active_partner_data.id, values.partner);
                    }}
                    enableReinitialize
                    initialValues={initialValues}>
                    {({ values, touched, errors, isValid }) => (
                        <Form>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
                                        <Grid item>
                                            <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3e3f60' }}>Re-assigning User</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton className={classes.closeButton} onClick={handleDeletePartnerDialogClose}>
                                                <Close />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 16, color: '#3e3f60' }}>To remove this user, you will need to assign them to another partner before proceeding.</Typography>
                                </Grid>
                                <Grid item>
                                    <Field name="partner">
                                        {({ field }) => (
                                            <Grid item>
                                                <Select
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...field}
                                                    style={{
                                                        borderRadius: 0,
                                                        borderColor: '#c6c9db'
                                                    }}
                                                    value={values.partner}
                                                    error={touched.partner && !!errors.partner ? true : false}>
                                                    <MenuItem value={false} selected disabled>Select a partner...</MenuItem>
                                                    {
                                                        partners && active_partner_data && partners.map((user, key) => {
                                                            if (user.id !== active_partner_data.id) {
                                                                return (
                                                                    <MenuItem key={key} value={user.id}>{user.name}</MenuItem>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </Select>
                                                <p className="input-error">
                                                    {touched.partner ? errors.partner : null}
                                                </p>
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Button className={classes.saveChangesButton} disabled={!isValid} type="submit">Confirm Changes</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className={classes.cancelButton} onClick={handleDeletePartnerDialogClose}>No, Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Dialog>
    );
}

export default DeletePartnerDialog;