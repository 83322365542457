import React, {useState, useEffect} from 'react';
import {Grid, IconButton, List, ListItem, ListItemText, Typography} from '@material-ui/core';
import ApprovalsFormHeader from './components/ApprovalsFormHeader';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import InputFieldMui from '../Components/TextInputMui/InputFieldMui';
import CheckBoxMui from '../Components/CheckBoxMui/CheckBoxMui';
import DatePickerMui from '../Components/DatePickerMui/DatePickerMui';
import TextareaField from '../Components/TextInputMui/TextareaFieldMui';
import ApprovalsElementLayout from './components/ApprovalsElementLayout';
import ApprovalsButtons from './components/ApprovalsButtons';
import DropzoneMui from '../Components/DropzoneMui/DropzoneMui';
import axios from 'axios';
import {Delete} from '@material-ui/icons';

const initialValues = {
  owner: '',
  market: '',
  email: '',
  contactNumber: '',
  leadContactName: '',
  leadContactEmail: '',
  briefingForms: [],
  dateOfPromotion: null,
  preferredDelivery: null,
  businessCaseObjectives: '',
  anyOtherRightsOfInterest: '',
  supportingDocuments: [],
};

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  owner: Yup.string().required('Required!'),
  market: Yup.string().required('Required!'),
  email: Yup.string().email('Invalid email format').required('Required!'),
  contactNumber: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Required!'),
  leadContactName: Yup.string().required('Required!'),
  leadContactEmail: Yup.string()
      .email('Invalid email format')
      .required('Required!'),
  dateOfPromotion: Yup.string().required('required').nullable(),
  preferredDelivery: Yup.string().required('required').nullable(),
  businessCaseObjectives: Yup.string().required('Required!'),
  anyOtherRightsOfInterest: Yup.string().required('Required!'),
  supportingDocuments: Yup.array()
      .min(1, 'Supporting document field must have at least one document!')
      .required('Required'),
  briefingForms: Yup.array()
      .min(1, 'Please select at least one!')
      .required('Required'),
});

const ActivationBriefingForm = ({onSubmit, classes, closeForm}) => {
  const [briefingForms, setBriefingForms] = useState([]);

  useEffect(() => {
    axios.get('/api/brand-request-briefing-forms')
        .then((res) => {
          setBriefingForms(res.data);
        });
  }, []);

  const handleRemoveItem = () => {

  };

  return (
    <ApprovalsElementLayout>
      <ApprovalsFormHeader
        title="Activation Briefing Form"
        closeHandler={closeForm}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values, 1);
        }}
      >
        {({values, isValid, setFieldValue, ...props}) => {
          return (
            <Form>
              <Grid container style={{marginBottom: '41px'}}>
                <Grid item xs={12} lg={6}>
                  <Typography className={classes.sectionTitle} style={{marginBottom: '12px'}}>
                                        Request Details
                  </Typography>
                  <InputFieldMui
                    style={{maxWidth: '280px'}}
                    label="Owner"
                    name="owner"
                  />
                  <InputFieldMui
                    style={{maxWidth: '280px'}}
                    label="Market"
                    name="market"
                  />
                  <InputFieldMui
                    style={{maxWidth: '280px'}}
                    label="Email"
                    name="email"
                  />
                  <InputFieldMui
                    style={{maxWidth: '280px'}}
                    label="Contact Number"
                    name="contactNumber"
                  />
                  <Typography style={{margin: '13px 0 6px 0'}}>
                                        Lead contact involved in project inc. supporting agencies
                  </Typography>
                  <InputFieldMui
                    style={{maxWidth: '280px'}}
                    label="Name"
                    name="leadContactName"
                  />
                  <InputFieldMui
                    style={{maxWidth: '280px'}}
                    label="Email"
                    name="leadContactEmail"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>

                  <CheckBoxMui
                    title="Briefing Form"
                    name="briefingForms"
                    values={values}
                    options={briefingForms}
                    style={{marginBottom: '24px'}}
                  />

                  <Grid item>
                    <Typography className={classes.sectionTitle} style={{marginBottom: '16px'}}>
                                            Summary of activity
                    </Typography>
                    <DatePickerMui
                      style={{
                        width: '173px',
                        height: '24px',
                        margin: '0 9px',
                      }}
                      label="Date of promotion (DD/MM/YY)"
                      name="dateOfPromotion"
                    />
                    <DatePickerMui
                      style={{
                        width: '173px',
                        height: '24px',
                        margin: '0 9px',
                      }}
                      label="Preferred delivery (DD/MM/YY)"
                      name="preferredDelivery"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{marginBottom: '36px'}}>
                <Typography className={classes.sectionTitle} style={{marginBottom: '18px'}}>
                                    Activity Briefing
                </Typography>
                <TextareaField
                  label="Business case/objectives"
                  name="businessCaseObjectives"
                  boxStyle={{marginBottom: '18px'}}
                  multiline
                />
                <TextareaField
                  label="Any other rights of interest?"
                  name="anyOtherRightsOfInterest"
                  boxStyle={{marginBottom: '18px'}}
                  multiline
                />
                <DropzoneMui
                  label="Upload supporting documents"
                  name="supportingDocuments"
                  style={{height: '104px'}}
                />
                {
                  values.supportingDocuments &&
                                    <List>
                                      {
                                        values.supportingDocuments.map((file, index) => (
                                          <ListItem
                                            key={index}
                                            dense
                                            disablepadding={true}>
                                            <ListItemText primary={file.name} />
                                            <IconButton edge="end" disableRipple onClick={() => {
                                              setFieldValue(values.supportingDocuments.splice(index, 1));
                                            }}>
                                              <Delete />
                                            </IconButton>
                                          </ListItem>
                                        ))
                                      }
                                    </List>
                }
              </Grid>
              <ApprovalsButtons isValid={isValid} />
            </Form>
          );
        }}
      </Formik>
    </ApprovalsElementLayout>
  );
};

export default ActivationBriefingForm;
