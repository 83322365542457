import React from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

export default ({request}) => {
    return (
        <div className="events-block-new cards fill">
            <h4>Request Type</h4>

            <ul>
                <li>
                    <label>ACCESS TYPE</label>
                    <span className="text-capitalize">{request.teamAsset.access_type} Access</span>
                </li>
                <li>
                    <label>REQUEST TYPE</label>
                    <span className="text-capitalize">{request.teamAsset.request_type}</span>
                </li>

            </ul>
            <h4>Request Details</h4>

            <ul>
                <li>
                    <label>ACCESS TYPE</label>
                    <span>{request.teamAsset.session_name}</span>
                </li>
                <li>
                    <label>SESSION DATE</label>
                    <span>{moment(request.teamAsset.session_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                </li>
                <li>
                    <label>TEAM ALLOCATION</label>
                    <span>{request.teamAsset.team?.name}</span>
                </li>
                <li>
                    <label>TIME ALLOCATION</label>
                    <span>{request.teamAsset.time}h</span>
                </li>
                {request.teamAsset?.access_type === 'players' &&
                    <li>
                        <label>PLAYER ALLOCATION</label>
                        <span>{request.teamAsset.player_qty}x Players</span>
                    </li>
                }
                {request.teamAsset.request_type === 'partner event' &&
                <li>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <label>PROPOSED DATES</label>
                        </Grid>
                        <Grid item>
                            {request.propositions.map((proposition, key) => {
                                return (
                                    <Grid item key={key}>
                                        {moment(proposition.event_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    </Grid>
                </li>
                }
            </ul>
        </div>
    );
};
