import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as Yup from 'yup';
import {makeStyles} from '@material-ui/core/styles';
import {Field, Formik} from 'formik';
import {TextField} from '@material-ui/core';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import {KeyboardDatePicker} from '@material-ui/pickers';
import Dropzone from 'react-dropzone';
import Button from '../Components/Button';
import './brandForm.scss';
import CalendarIcon from '~/Components/Icons/CalendarIcon';
import PreviewFile from '~/Components/PreviewFile';
import Typography from '@material-ui/core/Typography';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import {connect} from 'react-redux';
import {getTeam} from '~/Admin/InventoryManagement/inventoryActions';

export const TalentAccessRequestForm = (
    {
      onSubmit,
      players,
      staff,
      activeAsset,
      onBack,
      step,
      setStep,
      defaultRequest,
      submitButtonLabel,
      dispatch,
      ...values
    }) => {
  let defaultPropositions = [];

  if (defaultRequest && defaultRequest.propositions && defaultRequest.propositions.length) {
    defaultPropositions = defaultRequest.propositions;
  }

  const [loading, setLoading] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [previewFile, setPreviewFile] = React.useState(null);
  const [alternatives, setAlternatives] = React.useState(defaultPropositions.length > 0 ? defaultPropositions : [0]);

  submitButtonLabel = submitButtonLabel || 'SUBMIT';

  React.useEffect(() => {
    if (activeAsset && activeAsset.team_id) {
      dispatch(getTeam(activeAsset.team_id));
    }
  }, []);


  let defaultFiles = [];

  if (defaultRequest && defaultRequest.files && defaultRequest.files.length) {
    defaultFiles = defaultRequest.files;
  }

  let defaultPlayers = [];

  if (defaultRequest && defaultRequest.players && defaultRequest.players.length) {
    defaultPlayers = defaultRequest.players;
  }

  let defaultStaff = [];

  if (defaultRequest && defaultRequest.staff && defaultRequest.staff.length) {
    defaultStaff = defaultRequest.staff;
  }

  const initialValues = {
    description: defaultRequest.description || '',
    files: defaultFiles,
    propositions: defaultPropositions,
    [activeAsset.access_type]: activeAsset.access_type === 'players' ? defaultPlayers : defaultStaff,
  };

  const schema = {
    description: Yup.string()
        .max(300)
        .required('Required'),
  };

  if (activeAsset.access_type === 'staff') {
    schema['staff'] = Yup.array()
        .min(1)
        .required('Required');
  }

  if (activeAsset.access_type === 'players') {
    schema['players'] = Yup.array()
        .min(1)
        .max(activeAsset.player_qty * 3)
        .required('Required');
  }

  if (activeAsset.access_type === 'staff' && activeAsset.request_type === 'partner event') {
    schema['propositions'] = Yup.array().min(1).required('Required');
  }

  const RequestSchema = Yup.object().shape(schema);

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
      return;
    }
    if (onBack) {
      onBack();
    }
  };

  const setStartDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 2);
    return currentDate.getFullYear() + '-' + Number(currentDate.getMonth() + 1) + '-' + currentDate.getDate();
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const payload = new FormData();

    for (const field in values) {
      if (field === 'propositions') {
        for (let i = 0; i < values[field].length; i++) {
          const {event_date, event_location} = values[field][i];
          payload.append(`propositions[${i}][event_date]`, event_date);
          payload.append(`propositions[${i}][event_location]`, event_location);
        }
      } else if (field === 'players' || field === 'staff') {
        values[field].forEach((member) => {
          payload.append(`${activeAsset.access_type}[]`, member.id || member);
        });
      } else if (field !== 'files') {
        payload.append(field, values[field]);
      }
    }

    for (let i = 0; i < values.files.length; i++) {
      if (values.files[i].id) {
        payload.append(values.files[i].id, '1');
      } else {
        payload.append('files[]', values.files[i]);
      }
    }

    if (onSubmit) {
      onSubmit(payload).finally(() => {
        setLoading(false);
      });
    }
  };

  const previewUpload = (file) => {
    let name; let path;

    if (file.original_name) {
      name = file.original_name;
      path = file.download;
    } else {
      name = file.name;
      path = URL.createObjectURL(file);
    }

    setPreviewFile({name: name, path: path, media_type: file.media_type});
    setShowPreview(true);
  };

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.href = URL.createObjectURL(file);

    link.download = file.name;
    document.body.appendChild(link);

    link.click();
  };

  const checkFileType = (file) => {
    const imageRegex = new RegExp(/image\//);
    const videoRegex = new RegExp(/video\//);

    if (imageRegex.test(file.type)) {
      return 'photos';
    }
    if (videoRegex.test(file.type)) {
      return 'videos';
    }

    return false;
  };
  const printName = (file) => {
    const name = file.name || file.original_name;

    if (name.length > 34) {
      return name.substr(0, 35) + '...';
    }

    return name;
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: 0,
    },
    paper: {
      position: 'relative',
      marginLeft: '21px',
      minWidth: '322px',
      width: 'auto',
      marginTop: '9px',
      display: 'inline-block',
    },
    calendarButton: {
      'height': '32px',
      'padding': '4px 11px',
      'position': 'relative',
      'right': '-10px',
      '&:focus': {
        outline: 'none',
      },
    },
    calendarIcon: {
      position: 'relative',
      top: '-2px',
    },
    input: {
      'border': '1px solid #c6c9db',
      'padding': '8px 5px',
      'borderRadius': 0,
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
    dateError: {
      'border': '1px solid #F44336',
      'padding': '8px 15px',
      'borderRadius': 0,

      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  }));

  const classes = useStyles();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const members = activeAsset.access_type === 'players' ? players : staff;

  const propositionRow = (touched, errors, setFieldValue, values) => {
    return alternatives.map((alternative, key) => (
      <Grid container key={key} direction="column">
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item className="field-wrapper">
              <label>Proposed Event Date</label>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  autoOk
                  inputVariant="standard"
                  format="dd/MM/yyyy"
                  margin="normal"
                  minDate={setStartDate()}
                  value={(values.propositions[key] && values.propositions[key].event_date) ? values.propositions[key].event_date : null}
                  onChange={(date) => {
                    setFieldValue(`propositions.${key}.event_date`, moment(date).format(moment.HTML5_FMT.DATE));
                  }}
                  placeholder="DD/MM/YYYY"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    'className': classes.calendarButton,
                  }}
                  fullWidth
                  keyboardIcon={
                    <CalendarIcon
                      width="12px"
                      height="22px"
                      className={classes.calendarIcon}
                    />
                  }
                  classes={{root: classes.root}}
                  InputProps={{
                    className: touched.propositions && !!errors.propositions ?
                                            classes.dateError :
                                            classes.input,

                  }}
                  PopoverProps={{
                    classes: {
                      paper: classes.paper,
                    },
                  }}

                />
              </Grid>
              {touched.propositions && !!errors.propositions &&
                                <p className="input-error">{errors.propositions}</p>
              }
            </Grid>
            <Grid item className="field-wrapper">
              <label>Proposed Event Location</label>
              <Field
                name={`propositions.${key}.event_location`}
                type="text">
                {({field}) => (
                  <TextField
                    {...field}
                    value={values.propositions[key] ? values.propositions[key].event_location : ''}
                    placeholder="Location"
                    margin="normal"
                    InputProps={{className: classes.input}}
                    classes={{root: classes.root}}
                    fullWidth
                    variant="outlined"
                    error={touched.proposition_location && !!errors.proposition_location}
                    helperText={touched.proposition_location && !!errors.proposition_location ? errors.proposition_location : ''}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        </Grid>
        {alternative > 0 &&
                    <Grid item>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Typography className="alternative-option">
                                    ALTERNATIVE OPTION
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className="alternative-remove" onClick={() => {
                            const tmpAlternative = [...alternatives];
                            tmpAlternative.splice(key, 1);
                            setAlternatives(tmpAlternative);
                          }}>
                                    - Remove
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
        }

      </Grid>
    ));
  };

  return (
    <div className="talent-access-form">
      <Formik
        validationSchema={RequestSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({setFieldValue, submitForm, values, touched, errors}) => (
          <div>{!defaultRequest.id &&
                        <h3>FILL OUT YOUR REQUEST</h3>
          }
          <div className="row form-wrapper">
            <div className="col-lg-5 col-md-12 left-form">
              <Grid container direction="column">
                <Grid item>
                  <Typography className="section-label">Request type</Typography>
                </Grid>
                <Grid item className="details-wrapper">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography className="grey-label">ACCESS TYPE</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className="details-value"
                        style={{textTransform: 'capitalize'}}>
                        {activeAsset.access_type}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {activeAsset.access_type === 'staff' &&
                                        <Grid item className="details-wrapper">
                                          <Grid container direction="row" justifyContent="space-between">
                                            <Grid item>
                                              <Typography className="grey-label">REQUEST TYPE</Typography>
                                            </Grid>
                                            <Grid item>
                                              <Typography
                                                className="details-value">{activeAsset.request_type}</Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                }
                <Grid item style={{marginTop: 25}}>
                  <Typography className="section-label">Request details</Typography>
                </Grid>
                <Grid item className="details-wrapper">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography className="grey-label">COMMERCIAL SESSION</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className="details-value">{activeAsset.session_name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="details-wrapper">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography className="grey-label">SESSION DATE</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="details-value">{moment(activeAsset.session_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="details-wrapper">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography className="grey-label">TEAM ALLOCATION</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className="details-value">{activeAsset.team_id ? activeAsset.team.name : ''}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="details-wrapper">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography className="grey-label">TIME ALLOCATION</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="details-value">{activeAsset.time}h</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {activeAsset.access_type === 'players' &&
                                        <Grid item className="details-wrapper">
                                          <Grid container direction="row" justifyContent="space-between">
                                            <Grid item>
                                              <Typography className="grey-label">PLAYER ALLOCATION</Typography>
                                            </Grid>
                                            <Grid item>
                                              <Typography
                                                className="details-value">{activeAsset.player_qty}x Players</Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                }

              </Grid>
            </div>
            <div className="col-lg-7 col-md-12 right-form">
              <Grid container direction="column">
                <Grid item>
                  <Typography className="section-label">Request Form</Typography>
                </Grid>
                <Grid item className="field-wrapper">
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <label htmlFor="date">{activeAsset.access_type === 'players' ? 'Player Names' : 'Staff Names'}</label>
                        </Grid>
                        <Grid item className="talent-tooltip">
                          {activeAsset.access_type === 'players' && `You can select up to ${activeAsset.player_qty * 3} players`}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {members.length > 0 &&
                                                    <Autocomplete
                                                      multiple
                                                      options={members}
                                                      onChange={(event, value) => {
                                                        setFieldValue(activeAsset.access_type, []);
                                                        value.forEach((item, index) => {
                                                          setFieldValue(`${activeAsset.access_type}.${index}`, item.id);
                                                        });
                                                      }}
                                                      classes={{inputRoot: classes.input}}
                                                      disableCloseOnSelect
                                                      defaultValue={defaultRequest ? defaultRequest[activeAsset.access_type] : []}
                                                      getOptionLabel={(option) => option.name}
                                                      renderOption={(option, {selected}) => (
                                                        <React.Fragment>
                                                          <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{marginRight: 8}}
                                                            checked={selected}
                                                            value={option.id}
                                                            disabled={true}
                                                          />
                                                          {option.name}
                                                        </React.Fragment>
                                                      )}
                                                      getOptionSelected={((option, value) => option.id === value.id)}
                                                      renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" label=""
                                                          placeholder={'Select ' + activeAsset.access_type}
                                                          error={touched[activeAsset.access_type] && !!errors[activeAsset.access_type]}
                                                          helperText={touched[activeAsset.access_type] && !!errors[activeAsset.access_type] ? errors[activeAsset.access_type] : ''}
                                                        />
                                                      )}
                                                    />
                      }
                    </Grid>
                  </Grid>

                </Grid>
                {activeAsset.request_type === 'partner event' &&
                                        <>
                                          <Grid item>
                                            {propositionRow(touched, errors, setFieldValue, values)}
                                          </Grid>
                                          <Grid item>
                                            <span className="alternative"
                                              onClick={() => setAlternatives((prevState) => ([...prevState, 1]))}>
                                                    Add alternative option
                                            </span>
                                          </Grid>
                                        </>
                }

                <Grid item className="field-wrapper">
                  <label htmlFor="description">Request overview</label>
                  <Field
                    name="description"
                    type="text"
                    render={({field}) => (
                      <TextField
                        {...field}
                        placeholder="Overview"
                        margin="normal"
                        fullWidth
                        multiline={true}
                        rows={6}
                        value={values.description || ''}
                        InputProps={{className: classes.input}}
                        classes={{root: classes.root}}
                        variant="outlined"
                        error={touched.description && !!errors.description}
                        helperText={touched.description && !!errors.description ? errors.description : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item className="field-wrapper">
                  <Grid container direction="row">
                    <Grid item><label>Supporting Documents</label></Grid>
                    <Grid item>
                      <div className="fileupload">
                        <div className="upload-icon">
                          <img src="/images/icons/SVG/Upload-red.svg" alt="upload" />
                        </div>
                        <h4 className="upload-title">
                                                        Drag & Drop
                        </h4>
                        <div className="form-group">
                          <Dropzone
                            accept=".ai,.esp,.otf,.eps,.jpg,.jpeg,.png,.gif,.pdf,.doc, .docx,.ppt, .pptx,.xls, .xlsx,.mp3,.m4a,.wav,.mp3,.m4a,.wav,.mp4, .m4v,.mpg,.wmv,.mov,.avi,.swf,.zip,.epub"
                            minSize={0}
                            onDrop={(x) => {
                              const newFiles = [...values.files, ...x];
                              setFieldValue('files', newFiles);
                            }}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragReject,
                            }) => {
                              return (
                                <div {...getRootProps()} className="dropZone">
                                  <input {...getInputProps()} />
                                  {!isDragActive &&
                                                                            <span>Or you can <u>browse</u> your files</span>}
                                  {isDragActive &&
                                                                            !isDragReject &&
                                                                            'Drop it like it\'s hot!'}
                                  {isDragReject &&
                                                                            'File type not accepted, sorry!'}
                                </div>
                              );
                            }}
                          </Dropzone>
                        </div>
                      </div>
                      <div className="talent-tooltip">
                                                    Accepted Formats: ai, esp, otf, jpg, jpeg, png, gif, pdf, doc, docx,
                                                    ppt, pptx, xls, xlsx, mp3, m4a, wav, mp3, m4a, wav, mp4, m4v, mpg,
                                                    wmv, mov, avi, swf, zip, epub
                      </div>
                      <div className="input-error">
                        {touched.files && errors.files}
                      </div>
                    </Grid>
                  </Grid>


                </Grid>
              </Grid>

              <div className="row">
                <div className="col-md-12 text-left pt-4">
                  <span className="uploads-title">YOUR UPLOADS</span>
                </div>
              </div>
              <div className="row uploads-wrapper">
                <div className="col-md-12">
                  <ul className="uploads">
                    {values.files.length > 0 ?
                                                values.files.map((uploaded_image, index) => {
                                                  const fileType = checkFileType(uploaded_image);
                                                  if (!uploaded_image.media_type) {
                                                    uploaded_image['media_type'] = fileType;
                                                  }
                                                  return (
                                                    <li key={index} className="upload row">
                                                      <div className="upload__title col-9">
                                                        <span className="icons">
                                                          <img src="/images/icons/SVG/File Type/File.svg"
                                                            alt="file" />
                                                        </span>
                                                        <span className="filename"
                                                          title={uploaded_image.name || uploaded_image.original_name}>{printName(uploaded_image)}</span>{' '}
                                                      </div>

                                                      {(uploaded_image.name || uploaded_image.original_name) && (
                                                        <div className="right-buttons col-3 text-right">
                                                          <div className="row">
                                                            <div
                                                              className="col">{uploaded_image.media_type &&
                                                                                <img src="/images/icons/SVG/View.svg" alt="file"
                                                                                  onClick={() => previewUpload(uploaded_image)} />
                                                              }
                                                            </div>
                                                            <div className="col"
                                                            >
                                                              {uploaded_image.original_name ?
                                                                                <a href={uploaded_image.download}
                                                                                  download>
                                                                                  <img
                                                                                    src="/images/icons/SVG/Download.svg"
                                                                                    alt="file" />
                                                                                </a> :
                                                                                <img
                                                                                  src="/images/icons/SVG/Download.svg"
                                                                                  alt="file"
                                                                                  onClick={() => downloadFile(uploaded_image)} />
                                                              }

                                                            </div>
                                                            <div className="col">
                                                              <img src="/images/icons/SVG/Close.svg"
                                                                alt="close icon" onClick={() =>
                                                                  setFieldValue(
                                                                      'files',
                                                                      values.files.filter((f) => {
                                                                        const nameProperty = f.id ? 'original_name' : 'name';
                                                                        return f[nameProperty] !== uploaded_image[nameProperty];
                                                                      },
                                                                      ),
                                                                  )
                                                                } />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </li>
                                                  );
                                                }) :
                                                ''}
                  </ul>
                </div>
              </div>

            </div>
          </div>


          <div className="submit-wrapper">
            <Grid container direction="row" spacing={3} justifyContent="flex-end">
              {onBack || step === 2 && (
                <Grid item className="pr-0">
                  <Button
                    text="PREVIOUS STEP"
                    disabled={loading}
                    class="btn-grey"
                    onClick={handleBack}>
                  </Button>
                </Grid>
              )}

              {onSubmit && (
                <Grid item className="pr-0">
                  <Button
                    text={loading ? 'Working...' : submitButtonLabel}
                    disabled={loading}
                    class="color-red"
                    onClick={submitForm}>
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
          </div>
        )}
      </Formik>
      <PreviewFile
        previewFile={previewFile}
        showPreview={showPreview}
        setLoaded={() => {
        }}
        setShowPreview={() => setShowPreview(!showPreview)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeAsset: state.talentAccess.activeAsset,
  players: state.inventory.players,
  staff: state.inventory.staff,
});
export default connect(mapStateToProps)(TalentAccessRequestForm);
